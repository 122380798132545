import { getHomePath, getDocTypePath, getRoutePath, getStoryPath, getDocPath, defDocType, removeTrailingSlash, ensureStartingSlash, getCategoryPath } from '@component-controls/core';

const getPageList = (store, type = defDocType) => {
    if (store) {
        return Object.keys(store.docs).reduce((acc, key) => {
            const doc = store.docs[key];
            if (doc) {
                const { type: docType = defDocType } = doc;
                if (docType === type) {
                    return [...acc, doc];
                }
            }
            return acc;
        }, []);
    }
    return [];
};
const getUniquesByField = (store, field) => {
    return Object.keys(store.docs).reduce((acc, key) => {
        const doc = store.docs[key];
        const value = doc[field];
        const values = Array.isArray(value) ? value : [value];
        values.forEach(v => {
            if (v !== undefined) {
                if (typeof acc[v] === 'undefined') {
                    acc[v] = 0;
                }
                acc[v] = acc[v] = 1;
            }
        });
        return acc;
    }, {});
};
const getPageTabs = (page) => {
    if (page && page.tabs) {
        return Object.keys(page.tabs);
    }
    return [undefined];
};
const getDocPages = (store) => {
    const { pages = {}, categories = [] } = (store === null || store === void 0 ? void 0 : store.config) || {};
    const docPaths = [];
    if (store) {
        const homePath = getHomePath(store);
        Object.keys(pages).forEach(type => {
            if (!categories.includes(type)) {
                const page = pages[type];
                const docType = type;
                const docHomePath = getDocTypePath(store, page.basePath);
                const docs = getPageList(store, docType);
                const tabs = getPageTabs(page);
                tabs.forEach((tab, tabIndex) => {
                    const route = tabIndex > 0 ? tab : undefined;
                    docs.forEach(doc => {
                        var _a, _b;
                        const pagePath = getRoutePath(store, doc.route);
                        if (pagePath !== homePath && pagePath !== docHomePath) {
                            const stories = ((_a = page.sideNav) === null || _a === void 0 ? void 0 : _a.storyPaths) && ((_b = doc.stories) === null || _b === void 0 ? void 0 : _b.length)
                                ? doc.stories
                                : [undefined];
                            stories.forEach((storyId) => {
                                const { path, query } = getStoryPath(storyId, doc, store, route);
                                if (!docPaths.find(p => p.path === path)) {
                                    const result = {
                                        path,
                                        type: docType,
                                        docId: doc.title,
                                    };
                                    if (storyId) {
                                        result.storyId = storyId;
                                    }
                                    if (route) {
                                        result.activeTab = route;
                                    }
                                    const lastModified = doc.dateModified
                                        ? new Date(doc.dateModified).toISOString()
                                        : undefined;
                                    if (lastModified) {
                                        result.lastModified = lastModified;
                                    }
                                    if (query) {
                                        result.query = query;
                                    }
                                    docPaths.push(result);
                                }
                            });
                        }
                    });
                });
            }
            else {
                const cats = getUniquesByField(store, type);
                const catKeys = Object.keys(cats);
                catKeys.forEach(tag => {
                    const path = getDocPath(type, { title: tag, componentsLookup: {} }, store);
                    docPaths.push({
                        path,
                        type,
                        category: tag,
                    });
                });
            }
        });
    }
    return docPaths;
};

const getHomePages = (store) => {
    const { pages = {}, categories = [] } = (store === null || store === void 0 ? void 0 : store.config) || {};
    if (pages && store) {
        const docs = Object.keys(store.docs);
        const paths = Object.keys(pages)
            .map((type) => {
            const page = pages[type];
            const path = getDocTypePath(store, page.basePath);
            const typeDocs = docs.filter(key => type === (store.docs[key].type || defDocType));
            if (!typeDocs.length) {
                return { type, path: '' };
            }
            const docId = page.indexHome
                ? undefined
                : docs.find(key => {
                    const doc = store.docs[key];
                    return (removeTrailingSlash(ensureStartingSlash((doc === null || doc === void 0 ? void 0 : doc.route) || '')) ===
                        path);
                }) ||
                    docs.find(key => (store.docs[key].type || defDocType) === type);
            const doc = docId ? store.docs[docId] : undefined;
            const docStories = (doc === null || doc === void 0 ? void 0 : doc.stories) || [];
            const storyId = docStories.length
                ? docStories[0]
                : undefined;
            const result = {
                type,
                path,
                docIndex: true,
            };
            if (docId) {
                result.docId = docId;
            }
            if (storyId) {
                result.storyId = storyId;
            }
            const lastModified = (doc === null || doc === void 0 ? void 0 : doc.dateModified)
                ? new Date(doc === null || doc === void 0 ? void 0 : doc.dateModified).toISOString()
                : undefined;
            if (lastModified) {
                result.lastModified = lastModified;
            }
            return result;
        })
            .filter(({ path }) => path);
        const categoryPaths = categories
            .map(category => {
            const uniques = getUniquesByField(store, category);
            return {
                type: category,
                docIndex: true,
                path: Object.keys(uniques).length
                    ? getCategoryPath(store, category)
                    : '',
            };
        })
            .filter(({ path }) => path);
        return [...paths, ...categoryPaths];
    }
    return [];
};

const getIndexPage = (store) => {
    if (store) {
        const docs = Object.keys(store.docs);
        const homePath = getHomePath(store);
        const homePageId = docs.find(key => {
            const doc = store.docs[key];
            return getRoutePath(store, doc.route) === homePath;
        });
        const homePage = homePageId
            ? store.docs[homePageId]
            : docs.length > 0
                ? store.docs[docs[0]]
                : undefined;
        const docId = homePage === null || homePage === void 0 ? void 0 : homePage.title;
        const docStories = docId && store.docs[docId] ? store.docs[docId].stories || [] : [];
        const result = {
            path: homePath,
            type: (homePage === null || homePage === void 0 ? void 0 : homePage.type) || defDocType,
        };
        const lastModified = (homePage === null || homePage === void 0 ? void 0 : homePage.dateModified)
            ? new Date(homePage === null || homePage === void 0 ? void 0 : homePage.dateModified).toISOString()
            : undefined;
        if (lastModified) {
            result.lastModified = lastModified;
        }
        const storyId = docStories.length
            ? docStories[0]
            : undefined;
        if (storyId) {
            result.storyId = storyId;
        }
        if (docId) {
            result.docId = docId;
        }
        return result;
    }
    return {
        type: defDocType,
        path: '/',
    };
};

const getRoutes = (store) => {
    const routes = [];
    //home page
    const index = getIndexPage(store) || {};
    routes.push(index);
    //docs index pages
    const homePages = getHomePages(store);
    homePages.forEach(page => {
        routes.push(page);
    });
    //document pages
    const docPages = getDocPages(store);
    docPages.forEach(page => {
        routes.push(page);
    });
    return routes;
};

const getSiteMap = (store) => {
    if (store) {
        const config = store.config.siteMap;
        const { siteUrl = '' } = store.config;
        const sitePath = removeTrailingSlash(siteUrl);
        const siteMapPages = typeof config === 'object' ? config.pages : undefined;
        const pages = [];
        //home page
        const { path, lastModified } = getIndexPage(store) || {};
        pages.push({
            path,
            priority: (siteMapPages === null || siteMapPages === void 0 ? void 0 : siteMapPages.home.priority) || 1,
            lastModified,
        });
        const homePages = getHomePages(store);
        homePages.forEach(({ path, lastModified }) => {
            pages.push({
                path,
                priority: (siteMapPages === null || siteMapPages === void 0 ? void 0 : siteMapPages.index.priority) || 1,
                lastModified,
            });
        });
        const docPages = getDocPages(store);
        docPages.forEach(({ path, lastModified }) => {
            pages.push({
                path,
                priority: (siteMapPages === null || siteMapPages === void 0 ? void 0 : siteMapPages.doc.priority) || 1,
                lastModified,
            });
        });
        const sitemap = `<?xml version="1.0" encoding="UTF-8"?>
  <urlset xmlns="http://www.sitemaps.org/schemas/sitemap/0.9" xmlns:news="http://www.google.com/schemas/sitemap-news/0.9" xmlns:xhtml="http://www.w3.org/1999/xhtml" xmlns:mobile="http://www.google.com/schemas/sitemap-mobile/1.0" xmlns:image="http://www.google.com/schemas/sitemap-image/1.1" xmlns:video="http://www.google.com/schemas/sitemap-video/1.1">
  ${pages
            .map(({ path, priority, lastModified }) => `<url><loc>${sitePath}${path}</loc>${lastModified ? `<lastmod>${lastModified}</lastmod>` : ''}<changefreq>daily</changefreq><priority>${priority}</priority></url>`)
            .join('\n')}
  </urlset>  
  `;
        return sitemap;
    }
    return '';
};

export { getDocPages, getHomePages, getIndexPage, getPageTabs, getRoutes, getSiteMap, getUniquesByField };
