'use strict';

var themeUi = require('theme-ui');
var React = require('react');
var blocks = require('@component-controls/blocks');
var core = require('@component-controls/core');
var store = require('@component-controls/store');

/** @jsx jsx */
const ClassicPage = ({ controlsThreshold = 10 }) => {
    const story = store.useCurrentStory();
    const controlsCount = core.getControlsCount(story === null || story === void 0 ? void 0 : story.controls);
    const propsCount = store.useCurrentPropsCount();
    const splitControls = controlsCount > 0 &&
        controlsCount <= controlsThreshold &&
        (propsCount === 0 ||
            (controlsCount < propsCount && propsCount >= controlsThreshold));
    const mixedControls = !splitControls && controlsCount >= propsCount;
    return (themeUi.jsx(React.Fragment, null,
        themeUi.jsx(blocks.PackageVersion, null),
        themeUi.jsx(blocks.Description, null),
        themeUi.jsx(blocks.ComponentSource, { id: ".", title: "Component" }),
        themeUi.jsx(blocks.Playground, { title: "." },
            themeUi.jsx(blocks.Story, { id: "." })),
        splitControls && (themeUi.jsx(blocks.PropsTable, { flat: controlsCount === propsCount, of: ".", title: "Controls", visibility: "controls" })),
        (propsCount > 0 || (!splitControls && controlsCount > 0)) && (themeUi.jsx(blocks.PropsTable, { of: ".", title: mixedControls ? 'Controls' : 'Properties', flat: propsCount <= controlsThreshold && !mixedControls, visibility: splitControls ? 'info' : 'all' })),
        themeUi.jsx(blocks.ComponentCommits, { id: ".", title: "Commits" }),
        themeUi.jsx(blocks.ExternalDependencies, { id: ".", title: "External dependencies" }),
        themeUi.jsx(blocks.LocalDependencies, { id: ".", title: "Internal dependencies" }),
        themeUi.jsx(blocks.ComponentJSX, { id: ".", title: "Component JSX" }),
        themeUi.jsx(blocks.Stories, { dark: true })));
};
var ClassicPage$1 = {
    title: 'Documentation',
    component: ClassicPage,
    controlsThreshold: 10,
};

module.exports = ClassicPage$1;
