import React, { createContext, useContext, useState, useEffect, useMemo } from 'react';
import { getDefaultStore, CURRENT_STORY, getHomePath, defDocType, getDocPath, getComponentName, mergeControlValues, docStoryToId, formatStoryPath, getStoryPath } from '@component-controls/core';
export { l as loadStore } from './load-store.esm.js';
import '@component-controls/render/react';

const StoreContext = createContext({
    store: getDefaultStore(),
});
const StoreContextProvider = ({ store, children, }) => {
    return (React.createElement(StoreContext.Provider, { value: {
            store,
        } }, children));
};
/**
 * Returns the global store object
 */
const useStore = () => {
    const { store } = useContext(StoreContext);
    return store;
};
const ConfigContext = createContext({
    config: {},
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    setConfig: () => { },
});
const ConfigContextProvider = ({ children }) => {
    const store = useStore();
    const [config, setConfig] = useState(store.config);
    useEffect(() => {
        setConfig(store.config);
    }, [store, setConfig]);
    return (React.createElement(ConfigContext.Provider, { value: {
            config,
            setConfig,
        } }, children));
};
/**
 * Returns a configuration object and the setter method
 */
const useConfigState = () => {
    const { config, setConfig } = useContext(ConfigContext);
    return [config, setConfig];
};
/**
 * Returns the configuration object part of the store
 */
const useConfig = () => {
    const [config] = useConfigState();
    return config;
};
/**
 * Returns the current theme configuration and a setter method
 */
const useThemeState = () => {
    const { config, setConfig } = useContext(ConfigContext);
    return [
        config.theme,
        theme => {
            setConfig(Object.assign(Object.assign({}, config), { theme: theme || config.theme }));
        },
    ];
};
/**
 * returns current theme object
 */
const useTheme = () => {
    const [theme] = useThemeState();
    return theme;
};
const ActiveTabContext = createContext(undefined);
const ActiveTabContextProvider = ({ activeTab, children }) => {
    return (React.createElement(ActiveTabContext.Provider, { value: activeTab }, children));
};
/**
 * Returns the current active tab for documenta that have multiple tabs/pages
 */
const useActiveTab = () => useContext(ActiveTabContext);
/**
 * packageId Returns a package object from a package package id. The package id can come from a Document or a Component object.
 */
const usePackage = (packageId) => {
    const store = useStore();
    return packageId ? store.packages[packageId] : undefined;
};
const OptionsContext = createContext({});
const OptionsContextProvider = ({ userData = {}, children, }) => {
    return (React.createElement(OptionsContext.Provider, { value: userData }, children));
};
const useUserData = () => useContext(OptionsContext);

const DocumentContext = createContext(undefined);
const DocumentContextProvider = ({ docId, children, }) => {
    const store = useStore();
    return (React.createElement(DocumentContext.Provider, { value: docId ? store.docs[docId] : undefined }, children));
};
/**
 * Retrieves a Document object from a document id
 */
const useDocument = (docId = CURRENT_STORY) => {
    const store = useStore();
    const current = useCurrentDocument();
    return docId === CURRENT_STORY ? current : store.docs[docId];
};
const useGetDocument = () => {
    const store = useStore();
    return (docId) => store.docs[docId];
};
/**
 * Returns the currently selected document
 */
const useCurrentDocument = () => useContext(DocumentContext);
/**
 * Returns the package information for the currently selected document
 */
const useDocPackage = () => {
    const store = useStore();
    const doc = useCurrentDocument();
    return doc && doc.package ? store.packages[doc.package] : undefined;
};
/**
 *  Returns a key-value object of all documents in the store
 */
const useDocs = () => {
    const store = useStore();
    return store.docs;
};
/**
 *  Returns an array of all documents in the store
 */
const usePages = () => {
    const store = useStore();
    return Object.keys(store.docs).map(key => store.docs[key]);
};
const docSortFn = (sort) => (p1, p2) => {
    const v1 = p1[sort];
    const v2 = p2[sort];
    if (v1 && v2) {
        return sort.startsWith('date')
            ? -v1.localeCompare(v2)
            : v1.localeCompare(v2);
    }
    if (!v1 && !v2) {
        return 0;
    }
    return v1 ? -1 : 1;
};
const DocsSortContext = createContext({
    sort: {},
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    setSort: () => { },
});
const DocsSortContextProvider = ({ children }) => {
    const [sort, setSort] = useState({});
    return (React.createElement(DocsSortContext.Provider, { value: {
            sort,
            setSort: (type, newOrder) => setSort(Object.assign(Object.assign({}, sort), { [type]: newOrder })),
        } }, children));
};
/**
 * Returns the doc sort order and a setter to update the sort order
 * for a specific doc type
 */
const useDocSort = (type) => {
    const { sort, setSort } = useContext(DocsSortContext);
    return [sort[type] || 'date', newSort => setSort(type, newSort)];
};
const getDocsByType = (docs, type) => Object.keys(docs).reduce((acc, key) => {
    const doc = docs[key];
    if (doc) {
        const { type: docType = defDocType } = doc;
        if (docType === type) {
            return [...acc, Object.assign({}, doc)];
        }
    }
    return acc;
}, []);
/**
 * Returns an array of all documents of a specific doc type
 */
const useDocByType = (type) => {
    const docs = useDocs();
    return useMemo(() => getDocsByType(docs, type), [docs, type]);
};
const useGetDocByType = () => {
    const docs = useDocs();
    return (type) => getDocsByType(docs, type);
};
/**
 * Returns a sorted list of documents of a specific doc type. Uses the sort order state.
 */
const useSortedDocByType = (type) => {
    const docs = useDocByType(type);
    const [sort] = useDocSort(type);
    return useMemo(() => [...docs].sort(docSortFn(sort)), [docs, sort]);
};
/**
 * Returns a global object of key/value pairs with counts of documents per doc type
 */
const useDocTypeCount = () => {
    const store = useStore();
    const homePath = useMemo(() => getHomePath(store), [store]);
    const getByDocType = useGetDocByType();
    const { pages = {} } = (store === null || store === void 0 ? void 0 : store.config) || {};
    return Object.keys(pages).reduce((acc, type) => {
        const docs = getByDocType(type);
        const home = docs.length
            ? docs.find(doc => doc.route === homePath) || docs[0]
            : undefined;
        return Object.assign(Object.assign({}, acc), { [type]: { count: docs.length, home } });
    }, {});
};
const useNavigationLinks = (doc) => {
    const docId = doc === null || doc === void 0 ? void 0 : doc.title;
    const type = (doc === null || doc === void 0 ? void 0 : doc.type) || defDocType;
    const docs = useDocByType(type);
    const store = useStore();
    const activeTab = useActiveTab();
    const result = {};
    //next page
    const index = docs.findIndex(p => p.title === docId);
    if (index >= 0 && index < docs.length - 1) {
        const nextDoc = docs[index + 1];
        result.nextPage = Object.assign(Object.assign({}, nextDoc), { link: getDocPath(nextDoc.type || defDocType, nextDoc, store, nextDoc.title, activeTab) });
    }
    //prev page
    if (index > 0) {
        const prevDoc = docs[index - 1];
        result.prevPage = Object.assign(Object.assign({}, prevDoc), { link: getDocPath(prevDoc.type || defDocType, prevDoc, store, prevDoc.title, activeTab) });
    }
    return result;
};
/**
 * Returns the next/previous page objects for the current document
 */
const useNavigationInfo = () => {
    const doc = useCurrentDocument();
    return useNavigationLinks(doc);
};
/**
 * Returns a link to a document from a DocType, document id and active tab
 */
const useDocumentPath = (type = defDocType, docId, activeTab) => {
    const doc = useDocument(docId);
    const store = useStore();
    return getDocPath(type, doc, store, docId, activeTab);
};
const useGetDocumentPath = () => {
    const store = useStore();
    return (type = defDocType, docId, activeTab) => {
        const doc = store.docs[docId];
        return getDocPath(type, doc, store, docId, activeTab);
    };
};
/**
 * Returns the descript for a document page. It uses the doc.description property if available, or if there is a component assigned to the document will return the component's name.
 */
const useDocDescription = (doc) => {
    var _a;
    const store = useStore();
    if (!doc) {
        return undefined;
    }
    if (doc.description) {
        return doc.description;
    }
    const componentName = getComponentName(doc.component);
    if (componentName) {
        const component = doc.componentsLookup
            ? store.components[doc.componentsLookup[componentName]]
            : undefined;
        if ((_a = component === null || component === void 0 ? void 0 : component.info) === null || _a === void 0 ? void 0 : _a.description) {
            return component.info.description;
        }
    }
    return undefined;
};
const useSearch = () => {
    const store = useStore();
    return store.search ? store.search(store) : undefined;
};

/**
 * Returns the number of documents by unique values in their `category` field
 */
const useDocPropCount = (category) => {
    const [state, setState] = useState({});
    const docs = useDocs();
    useEffect(() => {
        setState(Object.keys(docs).reduce((acc, key) => {
            const doc = docs[key];
            const value = doc[category];
            const values = Array.isArray(value) ? value : [value];
            values.forEach(v => {
                if (v !== undefined) {
                    if (typeof acc[v] === 'undefined') {
                        acc[v] = { count: 0 };
                    }
                    acc[v].count = acc[v].count + 1;
                }
            });
            return acc;
        }, {}));
    }, [category, docs]);
    return state;
};
/**
 * Returns an array of documents that have a specific value in their `category` field
 */
const useDocsByCategory = (category, value) => {
    const [state, setState] = useState([]);
    const docs = useDocs();
    const [sort] = useDocSort(category);
    useEffect(() => {
        setState(Object.keys(docs)
            .filter(key => {
            const doc = docs[key];
            const catValue = doc[category];
            if (value === undefined) {
                return catValue !== undefined;
            }
            const catValues = Array.isArray(catValue) ? catValue : [catValue];
            return catValues.some(v => v === value);
        })
            .map(key => docs[key])
            .sort(docSortFn(sort)));
    }, [sort, docs, category, value]);
    return state;
};

const StoryContext = createContext({
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    updateStory: () => { },
});
const StoryContextProvider = ({ storyId, values, children }) => {
    const { store } = useContext(StoreContext);
    const [, setStory] = useState(storyId ? store.stories[storyId] : undefined);
    //workaround gatsby ssr not updating classnames on active item
    const [client, setClient] = useState('ssr');
    useEffect(() => {
        const onObserver = (updatedStory) => {
            if ((updatedStory === null || updatedStory === void 0 ? void 0 : updatedStory.id) === storyId) {
                setStory(updatedStory);
            }
        };
        store.addObserver(onObserver);
        const story = storyId
            ? store.stories[storyId]
            : undefined;
        if (story === null || story === void 0 ? void 0 : story.dynamicId) {
            setClient('client');
        }
        return () => store.removeObserver(onObserver);
    }, [store, storyId]);
    useEffect(() => {
        if (values && storyId) {
            const story = store.stories[storyId];
            const storyControls = story.controls || {};
            const newValue = Object.assign(Object.assign({}, story), { controls: mergeControlValues(storyControls, undefined, values) });
            store.updateStory(newValue);
            setStory(newValue);
        }
    }, [values, storyId, store]);
    return (React.createElement(StoryContext.Provider, { key: client, value: {
            story: storyId ? store.stories[storyId] : undefined,
            updateStory: newValue => {
                if (storyId) {
                    store.updateStory(newValue);
                    setStory(newValue);
                }
            },
        } }, children));
};
/**
 * Returns the currently selected story
 */
const useCurrentStory = () => {
    const { story } = useContext(StoryContext);
    return story;
};
/**
 * Retrieves a Story object from a story id
 */
const useStoryById = (storyId) => {
    const store = useStore();
    return store.stories[storyId];
};
/**
 * given a story name, will go through the store to find a story that matches it
 */
const useStoryIdFromName = (name) => {
    const store = useStore();
    return useMemo(() => {
        if (name) {
            for (const docId in store.docs) {
                const doc = store.docs[docId];
                const storyId = docStoryToId(docId, name);
                if (doc.stories && doc.stories.indexOf(storyId) > -1) {
                    return storyId;
                }
            }
        }
        return undefined;
    }, [name, store.docs]);
};
/**
 * Returns a story id from an input id or story name. The id can be '.', which means the current story.
 */
const useStoryId = ({ id = CURRENT_STORY, name, }) => {
    const story = useCurrentStory();
    const { id: currentStoryId } = story || {};
    return (useStoryIdFromName(name) || (id === CURRENT_STORY ? currentStoryId : id));
};
/**
 * Returns a story from an input id or story name. The id can be '.', which means the current story.
 */
const useStory = (props) => {
    const storyId = useStoryId(props);
    const store = useStore();
    const [story, setStory] = useState(storyId ? store.stories[storyId] : undefined);
    useEffect(() => {
        const onObserver = (updatedStory) => {
            if ((updatedStory === null || updatedStory === void 0 ? void 0 : updatedStory.id) === storyId) {
                setStory(updatedStory);
            }
        };
        setStory(storyId ? store.stories[storyId] : undefined);
        store.addObserver(onObserver);
        return () => store.removeObserver(onObserver);
    }, [store, storyId]);
    return story;
};
/**
 * Returns a story's component from an input id or story name. The id can be '.', which means the current story.
 */
const useStoryComponent = (props) => {
    const storyId = useStoryId(props);
    const store = useStore();
    return useMemo(() => {
        var _a;
        const story = storyId
            ? store.stories[storyId]
            : undefined;
        const storyComponent = story === null || story === void 0 ? void 0 : story.component;
        const componentName = getComponentName(storyComponent);
        const doc = story && story.doc ? store.docs[story.doc] : undefined;
        const component = componentName && ((_a = doc === null || doc === void 0 ? void 0 : doc.componentsLookup) === null || _a === void 0 ? void 0 : _a[componentName])
            ? store.components[doc.componentsLookup[componentName]]
            : undefined;
        return component;
    }, [store, storyId]);
};
/**
 * Returns a link to a story from a story id.
 */
const useStoryPath = (storyId) => {
    const store = useStore();
    const activeTab = useActiveTab();
    const story = store.stories[storyId];
    if (!story) {
        return '';
    }
    const doc = store.docs[(story === null || story === void 0 ? void 0 : story.doc) || ''];
    return formatStoryPath(getStoryPath(story.id, doc, store, activeTab));
};
const useGetStoryPath = () => {
    const store = useStore();
    return (storyId, activeTab) => {
        const story = store.stories[storyId];
        const doc = story && story.doc ? store.docs[story.doc] : undefined;
        return formatStoryPath(getStoryPath(storyId, doc, store, activeTab));
    };
};

const ControlsContext = createContext({
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    updateValue: () => { },
});
const ControlsContextStoryProvider = ({ children }) => {
    const { story, updateStory } = useContext(StoryContext);
    return (React.createElement(ControlsContext.Provider, { value: {
            controls: story ? story.controls : undefined,
            updateValue: (name, newValue) => {
                if (story) {
                    const storyControls = story.controls || {};
                    updateStory(Object.assign(Object.assign({}, story), { controls: mergeControlValues(storyControls, name, newValue) }));
                }
            },
        } }, children));
};
/**
 * Returns the controls associated with a story
 */
const useStoryControls = (storyId) => {
    const story = useStoryById(storyId);
    return story ? story.controls : undefined;
};
const ControlsStateProvider = ({ controls, onChange, children, }) => {
    return (React.createElement(ControlsContext.Provider, { value: {
            controls,
            updateValue: onChange,
        } }, children));
};
/**
 * Retruns a controls and a setter function from the current controls context, given a control name
 *
 */
const useControl = (name) => {
    const { controls, updateValue } = useContext(ControlsContext);
    const control = controls ? (name ? controls[name] : controls) : undefined;
    const setValue = (value) => {
        updateValue(name, value);
    };
    return [control, setValue];
};

/**
 * If the `of` property is specified, will return the listed components, if `of` is not specified, will return the components specified in the component and subcomponent properties of the document or the story.
 */
const useComponents = ({ of = CURRENT_STORY, name, }) => {
    const store = useStore();
    const story = useStory({ id: name });
    const currentDoc = useCurrentDocument();
    return useMemo(() => {
        const { component: storyComponentName } = story || {};
        const storyComponent = getComponentName(storyComponentName);
        const doc = story && story.doc ? store.docs[story.doc] : currentDoc;
        const component = storyComponent && doc && doc.componentsLookup
            ? store.components[doc.componentsLookup[storyComponent]]
            : undefined;
        let components = undefined;
        const getComponents = (components) => {
            const getComponent = (name) => {
                var _a;
                return ((_a = doc === null || doc === void 0 ? void 0 : doc.componentsLookup) === null || _a === void 0 ? void 0 : _a[name]) &&
                    (store === null || store === void 0 ? void 0 : store.components[doc.componentsLookup[name]]);
            };
            return store && doc && components
                ? Object.keys(components).reduce((acc, key) => {
                    const comp = components[key];
                    if (comp === CURRENT_STORY) {
                        const comps = {};
                        const name = getComponentName(doc.component);
                        if (name) {
                            const component = getComponent(name);
                            if (component) {
                                comps[name] = component;
                            }
                        }
                        if (doc.subcomponents) {
                            Object.keys(doc.subcomponents).forEach(subKey => {
                                var _a;
                                const name = getComponentName((_a = doc.subcomponents) === null || _a === void 0 ? void 0 : _a[subKey]);
                                if (name) {
                                    const component = getComponent(name);
                                    if (component) {
                                        comps[name] = component;
                                    }
                                }
                            });
                        }
                        return Object.assign(Object.assign({}, acc), comps);
                    }
                    const keys = doc.componentsLookup
                        ? Object.keys(doc.componentsLookup)
                        : [];
                    const name = keys.length === 1 ? keys[0] : getComponentName(comp);
                    if (name) {
                        const component = getComponent(name);
                        if (component) {
                            return Object.assign(Object.assign({}, acc), { [key]: component });
                        }
                    }
                    return acc;
                }, {})
                : {};
        };
        if (of === CURRENT_STORY && story) {
            if (component) {
                const name = getComponentName(component);
                if (name) {
                    components = Object.assign({ [name]: component }, getComponents(story.subcomponents));
                }
                else {
                    components = getComponents(story.subcomponents);
                }
            }
            else {
                components = getComponents(story.subcomponents);
            }
        }
        else {
            components = getComponents({ of });
        }
        return components;
    }, [currentDoc, of, store, story]);
};
/**
 * If the `of` property is specified, will return the listed component, if `of` is not specified, will return the component specified in the component property of the document or the story.
 */
const useComponent = ({ of = CURRENT_STORY, name, }) => {
    const story = useStory({ id: name });
    const currentDoc = useCurrentDocument();
    const store = useStore();
    return useMemo(() => {
        const doc = story && story.doc ? store.docs[story.doc] : currentDoc;
        let component;
        if (of === CURRENT_STORY) {
            component = story ? story.component : doc === null || doc === void 0 ? void 0 : doc.component;
        }
        else {
            component = of;
        }
        const componentName = getComponentName(component);
        return componentName && doc && doc.componentsLookup
            ? store.components[doc.componentsLookup[componentName]]
            : undefined;
    }, [currentDoc, of, store, story]);
};
/**
 * returns the number of prop-info properties count for the current document
 * if the current document has more than one component assigned - will return the sum
 */
const useCurrentPropsCount = () => {
    const store = useStore();
    const story = useCurrentStory();
    const components = useComponents({ name: story === null || story === void 0 ? void 0 : story.id });
    const doc = useCurrentDocument();
    return useMemo(() => components && doc
        ? Object.keys(components).reduce((acc, key) => {
            var _a;
            const component = doc.componentsLookup
                ? store.components[doc.componentsLookup[key]]
                : undefined;
            return acc + Object.keys(((_a = component === null || component === void 0 ? void 0 : component.info) === null || _a === void 0 ? void 0 : _a.props) || {}).length;
        }, 0)
        : 0, [components, doc, store]);
};

const StateRoot = ({ children, storyId, docId, store, userData = {}, values, activeTab, }) => {
    return (React.createElement(StoreContextProvider, { store: store },
        React.createElement(ConfigContextProvider, null,
            React.createElement(ActiveTabContextProvider, { activeTab: activeTab },
                React.createElement(OptionsContextProvider, { userData: userData },
                    React.createElement(DocumentContextProvider, { docId: docId },
                        React.createElement(DocsSortContextProvider, null,
                            React.createElement(StoryContextProvider, { storyId: storyId, values: values },
                                React.createElement(ControlsContextStoryProvider, null, children)))))))));
};

export { ActiveTabContextProvider, ConfigContext, ConfigContextProvider, ControlsContextStoryProvider, ControlsStateProvider, DocsSortContextProvider, DocumentContextProvider, OptionsContextProvider, StateRoot, StoreContext, StoreContextProvider, StoryContext, StoryContextProvider, docSortFn, useActiveTab, useComponent, useComponents, useConfig, useConfigState, useControl, useCurrentDocument, useCurrentPropsCount, useCurrentStory, useDocByType, useDocDescription, useDocPackage, useDocPropCount, useDocSort, useDocTypeCount, useDocs, useDocsByCategory, useDocument, useDocumentPath, useGetDocByType, useGetDocument, useGetDocumentPath, useGetStoryPath, useNavigationInfo, usePackage, usePages, useSearch, useSortedDocByType, useStore, useStory, useStoryById, useStoryComponent, useStoryControls, useStoryId, useStoryIdFromName, useStoryPath, useTheme, useThemeState, useUserData };
