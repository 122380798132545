import React, { useState, useRef, useEffect } from 'react';
import { jsx, Button, Flex, Box, Input, Slider, Label, Select, Textarea } from 'theme-ui';
import { ControlTypes, deepmerge, newControlValues, mergeControlValues } from '@component-controls/core';
import { TrashcanIcon, PlusIcon } from '@primer/octicons-react';
import { useControl, ControlsStateProvider } from '@component-controls/store';
import { __rest } from 'tslib';
import { Popover, Toggle, Keyboard, DOWN_ARROW, UP_ARROW } from '@component-controls/components';
import { HslaStringColorPicker, HslStringColorPicker, RgbaStringColorPicker, RgbStringColorPicker, HexColorPicker } from 'react-colorful';
import { FileReader } from 'global';

const PropertyEditors = {};
/**
 *
 * Property editors factory. Given a propey type, will return a editor compnent class.
 *
 * @param type: specify the type ie 'text'
 *
 * @returns a Property Editor class.
 */
const getPropertyEditor = (type) => PropertyEditors[type];
/**
 *
 * @param type: specify the type ie 'text'
 * @param editor: a Property Editor class.
 */
const addPropertyEditor = (type, editor) => {
    PropertyEditors[type] = editor;
};

const PopupInline = (_a) => {
    var { inline } = _a, props = __rest(_a, ["inline"]);
    return inline && props.tooltip ? (React.createElement("div", null, props.tooltip({}))) : (React.createElement(Popover, Object.assign({}, props)));
};

const EditButton = props => (jsx(Button, Object.assign({ sx: { py: 1 } }, props)));

/** @jsx jsx */
const ChildContainer$1 = props => (jsx(Box, Object.assign({ css: {
        maxWidth: 800,
        padding: 15,
        boxSizing: 'content-box',
    } }, props)));
const RowEditor = ({ propName, control, row, Editor, onChange, }) => {
    const controls = {
        [propName]: control.rowType[propName].type === ControlTypes.OBJECT
            ? deepmerge(control.rowType[propName], {
                value: Object.keys(row[propName]).reduce((a, k) => (Object.assign(Object.assign({}, a), { [k]: {
                        value: row[propName] ? row[propName][k] : {},
                    } })), {}),
            })
            : deepmerge(control.rowType[propName], {
                value: row[propName],
            }),
    };
    return (jsx(ControlsStateProvider, { controls: controls, onChange: onChange },
        jsx(Editor, { name: propName })));
};
/**
 * Array control editor.
 *
 */
const ArrayEditor = ({ name, editLabel = 'edit...', }) => {
    const [control, setProp] = useControl(name);
    const { editLabel: controlEditLabel, inline } = control;
    const [isOpen, setIsOpen] = useState(false);
    const handleChange = (rowIndex, propName, value) => {
        if (Array.isArray(control.value)) {
            setProp(control.value.map((row, index) => rowIndex === index ? Object.assign(Object.assign({}, row), { [propName]: value }) : row));
        }
    };
    const handleOnDelete = (rowIndex) => {
        if (Array.isArray(control.value)) {
            setProp(control.value.filter((row, index) => index !== rowIndex));
        }
    };
    const handleOnAdd = () => {
        if (Array.isArray(control.value)) {
            setProp([...control.value, newControlValues(control.rowType)]);
        }
    };
    return (jsx(PopupInline, { inline: inline, trigger: "click", placement: "bottom", tooltipShown: isOpen, onVisibilityChange: (isVisible) => {
            setIsOpen(isVisible);
        }, tooltip: () => (jsx(ChildContainer$1, null,
            jsx("table", null,
                !inline && (jsx("thead", null,
                    jsx("tr", null, control.rowType &&
                        Object.keys(control.rowType).map(key => (jsx("th", { key: `head_${key}` }, control.rowType[key].label || key)))))),
                jsx("tbody", null, Array.isArray(control.value) &&
                    control.value.map((row, idx) => (jsx("tr", { key: `array_row_${idx}` },
                        typeof row === 'object' &&
                            Object.keys(control.rowType)
                                .map(key => {
                                const Editor = getPropertyEditor(control.rowType[key].type);
                                return Editor ? (jsx("td", { key: `row_data_${idx}_${key}` },
                                    jsx(Flex, null,
                                        jsx(RowEditor, { control: control, propName: key, row: row, Editor: Editor, onChange: (propName, value) => handleChange(idx, propName, value) })))) : null;
                            })
                                .filter(r => r),
                        jsx("td", null,
                            jsx(Button, { onClick: () => handleOnDelete(idx), "aria-label": "delete row", sx: { py: 1 } },
                                jsx(TrashcanIcon, null)))))))),
            jsx(EditButton, { onClick: handleOnAdd, "aria-label": "add new row" },
                jsx(PlusIcon, null),
                ` `,
                "Add row"))) },
        jsx(Button, { "aria-label": "edit the properties of the array" },
            controlEditLabel || editLabel,
            jsx(Box, null))));
};
addPropertyEditor(ControlTypes.ARRAY, ArrayEditor);

/**
 * Boolean control editor. Uses the Toggle component.
 *
 */
const BooleanEditor = (_a) => {
    var _b;
    var { name } = _a, rest = __rest(_a, ["name"]);
    const [control, onChange] = useControl(name);
    return (React.createElement(Toggle, Object.assign({ id: name, onChange: checked => onChange(checked), checked: (_b = control.value) !== null && _b !== void 0 ? _b : false }, rest)));
};
addPropertyEditor(ControlTypes.BOOLEAN, BooleanEditor);

/**
 * Button control editor.
 */
const ButtonEditor = (_a) => {
    var { name } = _a, rest = __rest(_a, ["name"]);
    const [control] = useControl(name);
    return (React.createElement(Button, Object.assign({ name: name, onClick: () => (control.onClick ? control.onClick(control) : {}) }, rest), name));
};
addPropertyEditor(ControlTypes.BUTTON, ButtonEditor);

const sxProps = {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    width: 200,
    height: 200,
    userSelect: 'none',
    cursor: 'default',
    '.react-colorful__saturation': {
        position: 'relative',
        flexGrow: 1,
        borderBottom: (t) => { var _a; return `12px solid ${(_a = t.colors) === null || _a === void 0 ? void 0 : _a.text}`; },
        borderRadius: '8px 8px 0 0',
        backgroundImage: (t) => { var _a, _b; return `linear-gradient(to top,  ${(_a = t.colors) === null || _a === void 0 ? void 0 : _a.text}, rgba(0, 0, 0, 0)), linear-gradient(to right,  ${(_b = t.colors) === null || _b === void 0 ? void 0 : _b.text}, rgba(255, 255, 255, 0))`; },
    },
    '.react-colorful__pointer-fill, .react-colorful__alpha-gradient': {
        content: '""',
        position: 'absolute',
        left: 0,
        top: 0,
        right: 0,
        bottom: 0,
        pointerEvents: 'none',
        borderRadius: 'inherit',
    },
    /* Improve elements rendering on light backgrounds */
    '.react-colorful__alpha-gradient, .react-colorful__saturation': {
        boxShadow: (t) => { var _a; return `inset 0 0 0 1px ${(_a = t.colors) === null || _a === void 0 ? void 0 : _a.shadow}`; },
    },
    '.react-colorful__hue, .react-colorful__alpha': {
        position: 'relative',
        height: 24,
    },
    '.react-colorful__hue': {
        background: 'linear-gradient( to right, #f00 0%, #ff0 17%, #0f0 33%, #0ff 50%, #00f 67%, #f0f 83%, #f00 100%)',
    },
    /* Round bottom corners of the last element: `Hue` for `ColorPicker` or `Alpha` for `AlphaColorPicker` */
    '.react-colorful__last-control': {
        borderRadius: '0 0 8px 8px',
    },
    '.react-colorful__interactive': {
        position: 'absolute',
        left: 0,
        top: 0,
        right: 0,
        bottom: 0,
        borderRadius: 'inherit',
        outline: 'none',
        /* Don't trigger the default scrolling behavior when the event is originating from this element */
        touchAction: 'none',
    },
    '.react-colorful__pointer': {
        position: 'absolute',
        zIndex: 1,
        boxSizing: 'border-box',
        width: 28,
        height: 28,
        transform: 'translate(-50%, -50%)',
        bg: 'background',
        border: (t) => { var _a; return `2px solid ${(_a = t === null || t === void 0 ? void 0 : t.colors) === null || _a === void 0 ? void 0 : _a.background}`; },
        borderRadius: '50%',
        boxShadow: (t) => { var _a; return `0 2px 4px ${(_a = t.colors) === null || _a === void 0 ? void 0 : _a.shadow}`; },
    },
    '.react-colorful__interactive:focus .react-colorful__pointer': {
        transform: 'translate(-50%, -50%) scale(1.1)',
    },
    /* Chessboard-like pattern for alpha related elements */
    '.react-colorful__alpha, .react-colorful__alpha-pointer': {
        bg: 'white',
        backgroundImage: `url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill-opacity=".05"><rect x="8" width="8" height="8"/><rect y="8" width="8" height="8"/></svg>')`,
    },
    /* Display the saturation pointer over the hue one */
    '.react-colorful__saturation-pointer': {
        zIndex: 3,
    },
    /* Display the hue pointer over the alpha one */
    '.react-colorful__hue-pointer': {
        zIndex: 2,
    },
};
/**
 * Color control editor.
 */
const ColorEditor = (_a) => {
    var { name } = _a, rest = __rest(_a, ["name"]);
    const [control, onChange] = useControl(name);
    const [isOpen, setIsOpen] = useState(false);
    const { kind } = control;
    const handleChange = (color) => {
        onChange(color);
    };
    let ColorPicker;
    switch (kind) {
        case 'hex':
        default:
            ColorPicker = HexColorPicker;
            break;
        case 'rgb': {
            ColorPicker = RgbStringColorPicker;
            break;
        }
        case 'rgba': {
            ColorPicker = RgbaStringColorPicker;
            break;
        }
        case 'hsl': {
            ColorPicker = HslStringColorPicker;
            break;
        }
        case 'hsla': {
            ColorPicker = HslaStringColorPicker;
            break;
        }
    }
    return (jsx(Popover, { trigger: "click", placement: "bottom", tooltipShown: isOpen, onVisibilityChange: (isVisible) => {
            setIsOpen(isVisible);
        }, tooltip: () => (jsx(Box, { sx: { p: 2 } },
            jsx(ColorPicker, Object.assign({ sx: sxProps, color: control.value, onChange: handleChange }, rest)))) },
        jsx(Button, { css: {
                paddingLeft: '10px',
                minHeight: '36px',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                position: 'relative',
            }, "aria-label": "edit the selected color" },
            jsx(Box, { css: {
                    left: 6,
                    width: 16,
                    height: 16,
                    marginRight: 10,
                    backgroundColor: control.value,
                    borderRadius: '1rem',
                } }),
            typeof control.value === 'string' && control.value,
            jsx(Box, null))));
};
addPropertyEditor(ControlTypes.COLOR, ColorEditor);

const toDate = (date) => typeof date === 'string' ? new Date(date) : date;
const formatDate = (date) => {
    if (date) {
        const year = `000${date.getFullYear()}`.slice(-4);
        const month = `0${date.getMonth() + 1}`.slice(-2);
        const day = `0${date.getDate()}`.slice(-2);
        return `${year}-${month}-${day}`;
    }
    return '';
};
const formatTime = (date) => {
    if (date) {
        const hours = `0${date.getHours()}`.slice(-2);
        const minutes = `0${date.getMinutes()}`.slice(-2);
        return `${hours}:${minutes}`;
    }
    return '';
};
/**
 * Date control editor.
 */
const DateEditor = (_a) => {
    var { name } = _a, rest = __rest(_a, ["name"]);
    const [control, onChange] = useControl(name);
    const [valid, setValid] = useState(true);
    const dateInputRef = useRef();
    const timeInputRef = useRef();
    useEffect(() => {
        if (valid !== false) {
            if (dateInputRef && dateInputRef.current) {
                dateInputRef.current.value = formatDate(toDate(control.value));
            }
            if (timeInputRef && timeInputRef.current) {
                timeInputRef.current.value = formatTime(toDate(control.value));
            }
        }
    }, [control.value, valid]);
    const onDateChange = (e) => {
        let isValid = false;
        const [year, month, day] = e.target.value.split('-');
        if (control.value) {
            const result = new Date(control.value);
            if (result.getTime()) {
                result.setFullYear(parseInt(year, 10));
                result.setMonth(parseInt(month, 10) - 1);
                result.setDate(parseInt(day, 10));
                if (result.getTime()) {
                    isValid = true;
                    onChange(result);
                }
            }
        }
        if (valid !== isValid) {
            setValid(isValid);
        }
    };
    const onTimeChange = (e) => {
        let isValid = false;
        const [hours, minutes] = e.target.value.split(':');
        if (control.value) {
            const result = new Date(control.value);
            if (result.getTime()) {
                result.setHours(parseInt(hours, 10));
                result.setMinutes(parseInt(minutes, 10));
                if (result.getTime()) {
                    onChange(result);
                    isValid = true;
                }
            }
        }
        if (valid !== isValid) {
            setValid(isValid);
        }
    };
    const { datePicker = true, timePicker = true } = control;
    return name ? (jsx("div", { sx: { display: 'flex', flexDirection: 'column' } },
        datePicker && (jsx(Input, Object.assign({ sx: {
                flex: 1,
            }, type: "date", max: "9999-12-31", ref: dateInputRef, id: `${name}date`, name: `${name}date`, onChange: onDateChange, placeholder: `enter a date for ${name}`, "aria-autocomplete": "none" }, rest))),
        timePicker && (jsx(Input, Object.assign({ sx: {
                flex: 1,
            }, type: "time", id: `${name}time`, name: `${name}time`, ref: timeInputRef, onChange: onTimeChange, placeholder: `enter a time for ${name}`, "aria-autocomplete": "none" }, rest))))) : null;
};
addPropertyEditor(ControlTypes.DATE, DateEditor);

function fileReaderPromise(file) {
    return new Promise(resolve => {
        const fileReader = new FileReader();
        fileReader.onload = (e) => resolve(e.currentTarget.result);
        fileReader.readAsDataURL(file);
    });
}
/**
 * Files control editor.
 */
const FilesEditor = (_a) => {
    var { name } = _a, rest = __rest(_a, ["name"]);
    const [control, onChange] = useControl(name);
    return (React.createElement(Input, Object.assign({ type: "file", name: name, multiple: true, onChange: (e) => {
            if (e.target.files) {
                Promise.all(Array.from(e.target.files).map(fileReaderPromise)).then(files => onChange(files));
            }
        }, accept: control.accept, placeholder: "select files" }, rest)));
};
addPropertyEditor(ControlTypes.FILES, FilesEditor);

const RangeLabel = props => (React.createElement(Box, Object.assign({ as: "span", variant: "forms.slider.label" }, props)));
const RangeWrapper = props => (React.createElement(Box, Object.assign({ as: "div", variant: "forms.slider.wrapper" }, props)));
/**
 * Number control editor.
 */
const NumberEditor = (_a) => {
    var { name } = _a, rest = __rest(_a, ["name"]);
    const [control, onChange] = useControl(name);
    const handleChange = (event) => {
        const { value } = event.target;
        let parsedValue = Number(value);
        if (Number.isNaN(parsedValue) || value === '') {
            parsedValue = null;
        }
        onChange(parsedValue);
    };
    const onKeyPressed = (key) => {
        switch (key) {
            case UP_ARROW:
                if (typeof control.value === 'number') {
                    onChange(Math.min(control.max || Number.MAX_VALUE, control.value + (control.step || 1)));
                }
                break;
            case DOWN_ARROW:
            default:
                if (typeof control.value === 'number') {
                    onChange(Math.max(control.min || 0, control.value - (control.step || 1)));
                }
                break;
        }
    };
    const hint = `a numeric value${typeof control.min != 'undefined' && typeof control.max !== 'undefined'
        ? ` between ${control.min} and ${control.max}`
        : ''}`;
    return (React.createElement(Keyboard, { keys: [DOWN_ARROW, UP_ARROW], onKeyDown: onKeyPressed }, control.range ? (React.createElement(RangeWrapper, null,
        React.createElement(RangeLabel, { "aria-labelledby": "" }, control.min),
        React.createElement(Slider, { value: control.value, name: name, min: control.min, max: control.max, step: control.step, onChange: handleChange, "aria-label": `select ${hint}` }),
        React.createElement(RangeLabel, null, `${control.value} / ${control.max}`))) : (React.createElement(Input, Object.assign({ value: control.value, type: "number", name: name, min: control.min, max: control.max, step: control.step, onChange: handleChange, "aria-label": `enter ${hint}` }, rest)))));
};
addPropertyEditor(ControlTypes.NUMBER, NumberEditor);

const ChildContainer = props => (React.createElement(Box, Object.assign({ css: {
        minWidth: 200,
        maxWidth: 800,
        padding: 15,
        boxSizing: 'content-box',
    } }, props)));
/**
 * Object control editor.
 */
const ObjectEditor = ({ name, editLabel = 'edit...', }) => {
    const [control, onChange] = useControl(name);
    const { editLabel: controlEditLabel, inline } = control;
    const [isOpen, setIsOpen] = useState(false);
    const handleChange = (childName, value) => {
        onChange(mergeControlValues(control.value, childName, value));
    };
    let children;
    if (typeof control.value === 'object') {
        children = Object.keys(control.value)
            .map(key => {
            const childProp = control.value
                ? control.value[key]
                : null;
            if (!childProp) {
                return null;
            }
            return {
                name: key,
                prop: childProp,
                node: getPropertyEditor(childProp.type),
            };
        })
            .filter(p => p && p.node);
    }
    const childControls = control.value || {};
    return (React.createElement(PopupInline, { inline: inline, trigger: "click", placement: "bottom", tooltipShown: isOpen, onVisibilityChange: (isVisible) => {
            setIsOpen(isVisible);
        }, tooltip: () => (React.createElement(ChildContainer, null,
            React.createElement("table", null,
                React.createElement("tbody", null, children &&
                    children.map(child => child ? (React.createElement("tr", { key: `editor_${child.name}` },
                        React.createElement("td", null, child.prop.label || child.name),
                        React.createElement("td", null,
                            React.createElement(ControlsStateProvider, { onChange: handleChange, controls: childControls },
                                React.createElement(child.node, { name: child.name }))))) : null))))) },
        React.createElement(EditButton, { "aria-label": "edit the properties of the object" }, controlEditLabel || editLabel)));
};
addPropertyEditor(ControlTypes.OBJECT, ObjectEditor);

const normalizeOptions = (options, propValue) => {
    const findLabelOption = (label, value) => {
        if (!value) {
            return {
                label: label || value,
                value,
            };
        }
        const val = value.value || value;
        if (typeof value !== 'object' || value === null)
            return { label: label || val, value: val };
        const vLabel = value.label || label || val;
        return {
            label: vLabel,
            value: val,
        };
    };
    let entries;
    if (Array.isArray(options)) {
        entries = options.reduce((acc, o) => {
            return [...acc, findLabelOption(null, o)];
        }, []);
    }
    else if (typeof options === 'object') {
        entries = Object.keys(options).reduce((acc, key) => {
            return [...acc, findLabelOption(key, options[key])];
        }, []);
    }
    else {
        console.error('invalid options parameter passed to controls');
        return {
            entries: [],
            selected: [],
        };
    }
    const selected = entries
        .filter(option => {
        if (Array.isArray(propValue)) {
            return propValue.findIndex((v) => v === option.value) >= 0;
        }
        return option.value === propValue;
    })
        .map(e => e.value);
    return { selected, entries };
};

const RadiosWrapper = (_a) => {
    var { isInline } = _a, rest = __rest(_a, ["isInline"]);
    return isInline ? (jsx(Box, Object.assign({ sx: {
            display: 'flex',
            flexWrap: 'wrap',
            alignItems: 'center',
            '> * + *': {
                marginLeft: 10,
            },
        } }, rest))) : (jsx(Box, Object.assign({}, rest)));
};
const RadioLabel = props => (jsx(Label, Object.assign({ sx: {
        width: 'unset',
        padding: '3px 0 3px 5px',
        lineHeight: '18px',
        display: 'inline-block',
    } }, props)));
const RadiosEditor = (_a) => {
    var { name } = _a, rest = __rest(_a, ["name"]);
    const [control, onChange] = useControl(name);
    const renderRadioButton = (entry) => {
        const id = `${entry.label}-${entry.value}`;
        return (jsx("div", { key: id },
            jsx("input", Object.assign({ type: "radio", id: id, name: name, value: entry.value, onChange: e => onChange(e.target.value), checked: entry.value === control.value ||
                    (typeof entry.value.toString === 'function' &&
                        entry.value.toString() === control.value) }, rest)),
            jsx(RadioLabel, { htmlFor: id }, entry.label)));
    };
    const renderRadioButtonList = () => {
        const { options, value } = control;
        const { entries } = normalizeOptions(options, value);
        return entries.map(entry => renderRadioButton(entry));
    };
    const isInline = control.display === 'inline-radio';
    return (jsx(RadiosWrapper, { isInline: isInline }, renderRadioButtonList()));
};

const CheckboxesWrapper = (_a) => {
    var { isInline } = _a, rest = __rest(_a, ["isInline"]);
    return isInline ? (jsx(Box, Object.assign({ sx: {
            display: 'flex',
            flexWrap: 'wrap',
            alignItems: 'center',
            '> * + *': {
                marginLeft: 10,
            },
        } }, rest))) : (jsx(Box, Object.assign({}, rest)));
};
const CheckboxFieldset = props => (jsx(Box, Object.assign({ as: "fieldset", sx: {
        border: 0,
        padding: 0,
        margin: 0,
    } }, props)));
const CheckboxLabel = props => (jsx(Label, Object.assign({ sx: {
        width: 'unset',
        padding: '3px 0 3px 5px',
        lineHeight: '18px',
        display: 'inline-block',
    } }, props)));
const CheckboxEditor = (_a) => {
    var { name } = _a, rest = __rest(_a, ["name"]);
    const [control, onChange] = useControl(name);
    const { options, value } = control;
    const { entries, selected } = normalizeOptions(options, value);
    const handleChange = (e) => {
        const currentValue = e.target.value;
        const values = entries
            .filter(entry => {
            const entryValue = typeof entry.value.toString === 'function'
                ? entry.value.toString()
                : entry.value;
            return selected.includes(entry.value)
                ? currentValue !== entryValue
                : currentValue === entryValue;
        })
            .map(entry => entry.value);
        onChange(values);
    };
    const renderCheckboxList = () => {
        return entries.map(entry => renderCheckbox(entry));
    };
    const renderCheckbox = (entry) => {
        const id = `${entry.label}-${entry.value}`;
        return (jsx("div", { key: id },
            jsx("input", Object.assign({ type: "checkbox", id: id, name: entry.label, value: entry.value, onChange: handleChange, checked: selected.includes(entry.value) }, rest)),
            jsx(CheckboxLabel, { htmlFor: id }, entry.label)));
    };
    const isInline = control.display === 'inline-check';
    return (jsx(CheckboxFieldset, null,
        jsx(CheckboxesWrapper, { isInline: isInline }, renderCheckboxList())));
};

const OptionsSelect = props => (jsx(Select, Object.assign({ sx: {
        color: 'black',
        flexBasis: '100%',
    } }, props)));
/**
 * Options control editor.
 */
const OptionsEditor = (_a) => {
    var { name } = _a, rest = __rest(_a, ["name"]);
    const [control, onChange] = useControl(name);
    const { display, options, value } = control;
    if (display === 'check' || display === 'inline-check') {
        return jsx(CheckboxEditor, Object.assign({ name: name }, rest));
    }
    if (display === 'radio' || display === 'inline-radio') {
        return jsx(RadiosEditor, Object.assign({ name: name }, rest));
    }
    if (display === undefined ||
        display === 'select' ||
        display === 'multi-select') {
        const { entries, selected } = normalizeOptions(options, value);
        const handleChange = (e) => onChange(e.target.value);
        const selectValue = entries.filter(op => selected.includes(op.value));
        const v = selectValue.length ? selectValue[0].value : '';
        return (jsx(OptionsSelect, Object.assign({ value: v, onChange: handleChange }, rest), entries.map((entry, idx) => (jsx("option", { key: entry.value || `option_key_${idx}`, value: entry.value }, entry.label)))));
    }
    return null;
};
addPropertyEditor(ControlTypes.OPTIONS, OptionsEditor);

/**
 * Text control editor.
 */
const TextEditor = (_a) => {
    var { name } = _a, rest = __rest(_a, ["name"]);
    const [control, onChange] = useControl(name);
    const { rows = 1 } = control;
    return rows > 1 ? (jsx(Textarea, Object.assign({ id: name, name: name, value: control.value, rows: rows, placeholder: control.placeholder, "aria-label": `enter a value for ${name}`, onChange: (event) => {
            const { value } = event.target;
            onChange(value);
        } }, rest))) : (jsx(Input, Object.assign({ id: name, name: name, value: control.value, placeholder: control.placeholder, "aria-label": `enter a value for ${name}`, onChange: (event) => {
            const { value } = event.target;
            onChange(value);
        } }, rest)));
};
addPropertyEditor(ControlTypes.TEXT, TextEditor);

export { ArrayEditor, BooleanEditor, ButtonEditor, ColorEditor, DateEditor, FilesEditor, NumberEditor, ObjectEditor, OptionsEditor, TextEditor, addPropertyEditor, getPropertyEditor };
