'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

var react = require('react');
var compose = require('./compose.js');
require('@component-controls/core');

/* eslint-disable react/display-name */
const render = props => {
    const { renderFn, values, context } = compose.composeFn(props);
    return typeof renderFn === 'function'
        ? react.createElement(() => renderFn(values, context))
        : react.createElement('div', 'invalid render function');
};

exports.render = render;
