export { c as customLoaderOptions, a as defBundleName, b as defCssFileName, d as defaultCompileProps } from './build.esm.js';
import merge from 'deepmerge';
import escape from 'escape-html';
import { __rest } from 'tslib';
import { storyNameFromExport as storyNameFromExport$1, toId } from '@storybook/csf';
import { useState, useCallback, useEffect } from 'react';
import faker from 'faker/locale/en_US';
export { default as faker } from 'faker/locale/en_US';

/**
 * given a component, return its name
 * @param component a string component name, or a component class, with a name or displayName static property
 */
const getComponentName = (component) => {
    return component
        ? typeof component === 'string'
            ? component
            : component.name || component.displayName
        : undefined;
};

const loadDefaultExport = (imported) => {
    var _a;
    return ((_a = imported) === null || _a === void 0 ? void 0 : _a.default) || imported;
};
const loadPageTab = (tab, imported) => {
    if (imported) {
        if (Array.isArray(tab)) {
            if (tab.length === 2) {
                const loaded = typeof tab[0] === 'string' ? loadDefaultExport(imported) : tab[0];
                return Object.assign(Object.assign({}, loaded), tab[1]);
            }
        }
        if (typeof tab === 'string') {
            return loadDefaultExport(imported);
        }
    }
    return tab;
};
const defaultBuildConfig = {
    siteRoot: '/',
    siteMap: {
        pages: {
            home: {
                priority: 1,
            },
            index: {
                priority: 0.8,
            },
            doc: {
                priority: 0.5,
            },
        },
    },
    categories: ['author', 'tags'],
    ignore: [
        'readme.md',
        'changelog.md',
        'code_of_conduct.md',
        'contributing.md',
        'license.md',
    ],
    pages: {
        story: {
            basePath: 'docs/',
            sideNav: {
                storyPaths: true,
                collapseSingle: true,
            },
            tabs: {
                page: '@component-controls/pages/ClassicPage',
                test: '@component-controls/pages/TestingPage',
                design: '@component-controls/pages/DesignPage',
            },
        },
        blog: {
            basePath: 'blogs/',
        },
        author: {
            basePath: 'authors/',
        },
        page: {
            basePath: 'pages/',
        },
        tags: {
            basePath: 'tags/',
        },
    },
    search: {
        /**
         * the search plugin search routine
         */
        searchingModule: '@component-controls/search-fusejs',
    },
};
const defaultRunConfig = {
    title: 'Component controls',
    description: 'Component controls stories. Write your components documentation with MDX and JSX. Design, develop, test and review in a single site.',
    language: 'en',
    author: '@component-controls',
    pages: {
        story: {
            label: 'Docs',
            navSidebar: true,
            contextSidebar: true,
            topMenu: true,
        },
        blog: {
            label: 'Blog',
            contextSidebar: true,
            topMenu: true,
            indexHome: true,
        },
        author: {
            label: 'Authors',
        },
        page: {
            label: 'Page',
            container: null,
        },
        tags: {
            label: 'Tags',
        },
    },
};
const convertConfig = (config) => {
    return config;
};

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
const concatMerge = (dest, src) => [...dest, ...src];
const deepMerge = (a, b) => merge(a, b, { arrayMerge: concatMerge });
const mergeArrays = (dest, src) => {
    const result = src.length > 0
        ? src.map((s, idx) => typeof s === 'object' &&
            typeof dest[idx] === 'object' &&
            idx < dest.length
            ? mergeConfig(dest[idx], s)
            : s)
        : src;
    return result;
};
const mergeConfig = (a, b) => merge(a, b, {
    arrayMerge: mergeArrays,
    customMerge: (key) => (src, dest) => {
        return key === 'tabs' ? dest || src : mergeConfig(src, dest);
    },
});
const deepMergeReplaceArrays = (a, b) => merge(a, b, {
    arrayMerge: (dest, src) => src,
});
const deepmerge = merge;

const extractStoryProps = (src) => {
    return {
        component: src.component,
        subcomponents: src.subcomponents,
        controls: src.controls,
        smartControls: src.smartControls,
        category: src.category,
        decorators: Array.isArray(src.decorators)
            ? src.decorators.filter(d => typeof d === 'function')
            : undefined,
        plugins: src.plugins,
    };
};
/**
 * merge commont story props - from config, document and story
 * @param dest the props to be overwritten
 * @param src the props to keep, unless they are undefined
 * @returns the merged props
 */
const mergeStoryProps = (dest = {}, src = {}) => {
    const destProps = extractStoryProps(dest);
    return deepMerge(destProps, src);
};

/**
 * Control field types
 * examples are provided for the different types:
 *
 */
var ControlTypes;
(function (ControlTypes) {
    /**
     * userName: {
     *   type: ControlTypes.TEXT,
     *   label: 'Name',
     *   value: 'Storyteller',
     * },
     */
    ControlTypes["TEXT"] = "text";
    /**
     *  age: {
     *   type: ControlTypes.NUMBER,
     *   label: 'Age',
     *   value: 78,
     *   range: true,
     *   min: 0,
     *   max: 90,
     *   step: 5,
     * },
     */
    ControlTypes["NUMBER"] = "number";
    /**
     * nice: {
     *  type: ControlTypes.BOOLEAN,
     *  label: 'Nice',
     *  value: true,
     * },
     */
    ControlTypes["BOOLEAN"] = "boolean";
    /**
     * fruit: {
     *   type: ControlTypes.OPTIONS,
     *   label: 'Fruit',
     *   value: 'apple',
     *   options: {
     *     Apple: 'apple',
     *     Banana: 'banana',
     *     Cherry: 'cherry',
     *   },
     * },
     */
    ControlTypes["OPTIONS"] = "options";
    /**
     *  birthday: {
     *   type: ControlTypes.DATE,
     *   label: 'Birthday',
     *   value: new Date(),
     *  },
     */
    ControlTypes["DATE"] = "date";
    /**
     * color: {
     *   type: ControlTypes.COLOR,
     *   value: '#000000',
     * },
     */
    ControlTypes["COLOR"] = "color";
    /**
     * button: {
     *  type: ControlTypes.BUTTON,
     *   onClick: () => {
     *    ... code to modify some variables
     *  }
     * },
     */
    ControlTypes["BUTTON"] = "button";
    /**
     *  style: {
     *    type: ControlTypes.OBJECT,
     *    label: 'Styles',
     *    value: {
     *      // do not randomize the border style
     *      border: { type: ControlTypes.TEXT, value: '2px dashed silver', data: null },
     *      borderRadius: { type: ControlTypes.NUMBER, value: 10 },
     *      padding: { type: ControlTypes.NUMBER, value: 10 },
     *    },
     *  }
     */
    ControlTypes["OBJECT"] = "object";
    /**
     * arrayItems: {
     *   type: ControlTypes.ARRAY,
     *   label: 'Items',
     *   rowType: {
     *     name: { type: ControlTypes.TEXT },
     *   },
     *   value: [{ name: 'Laptop' }, { name: 'Book' }, { name: 'Whiskey' }],
     * },
     */
    ControlTypes["ARRAY"] = "array";
    /**
     * images: {
     *   type: ControlTypes.FILES,
     *   label: 'Happy Picture',
     *   accept: 'image/*',
     *   value: [
     *     'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAQAAAC1+jfqAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAAmJLR0QA/4ePzL8AAAAHdElNRQfiARwMCyEWcOFPAAAAP0lEQVQoz8WQMQoAIAwDL/7/z3GwghSp4KDZyiUpBMCYUgd8rehtH16/l3XewgU2KAzapjXBbNFaPS6lDMlKB6OiDv3iAH1OAAAAJXRFWHRkYXRlOmNyZWF0ZQAyMDE4LTAxLTI4VDEyOjExOjMzLTA3OjAwlAHQBgAAACV0RVh0ZGF0ZTptb2RpZnkAMjAxOC0wMS0yOFQxMjoxMTozMy0wNzowMOVcaLoAAAAASUVORK5CYII=',
     *   ],
     * },
     */
    ControlTypes["FILES"] = "files";
})(ControlTypes || (ControlTypes = {}));

/* eslint-disable no-console */
const cleanQuotes = (txt) => typeof txt === 'string' ? txt.replace(/['"]+/g, '') : txt;
const handledTypes = [
    'boolean',
    'bool',
    'string',
    'number',
    'enum',
    'func',
    'shape',
    '(() => void)',
];
const controlFromProps = (name, propDef) => {
    var _a;
    if (!propDef) {
        return null;
    }
    let type = propDef.type.name;
    let splitType = undefined;
    // docgen typescript are ie "boolean | undefined"
    if (typeof type === 'string') {
        splitType = type.split(' | ');
        if (splitType.length > 1) {
            let found;
            // we have a typescrpit type definitio of "|" type
            for (let i = 0; i < splitType.length; i += 1) {
                const match = splitType[i];
                found = handledTypes.find(a => a === match);
                if (found !== undefined) {
                    type = found;
                    break;
                }
            }
            if (found === undefined) {
                type = 'enum';
            }
        }
    }
    function onClick() {
        // eslint-disable-next-line prefer-rest-params
        console.info(`${name}: `, arguments);
    }
    const defaultValue = propDef.defaultValue
        ? (_a = propDef.defaultValue.value) !== null && _a !== void 0 ? _a : propDef.defaultValue
        : undefined;
    switch (type) {
        case 'string': {
            let value;
            if (typeof defaultValue === 'string') {
                value = defaultValue;
            }
            value = cleanQuotes(value);
            const isColor = name.toLowerCase().includes('color');
            if (!value && propDef.type.required) {
                value = isColor ? 'red' : 'example';
            }
            return {
                type: isColor ? ControlTypes.COLOR : ControlTypes.TEXT,
                value,
            };
        }
        case 'boolean':
        case 'bool': {
            let value;
            if (defaultValue !== undefined) {
                // docgen typescript defaultValue.summary is actually a boolean type, not a string
                if (defaultValue === 'false' || defaultValue === false) {
                    value = false;
                }
                if (defaultValue === 'true' || defaultValue === true) {
                    value = true;
                }
            }
            return { type: ControlTypes.BOOLEAN, value };
        }
        case 'number': {
            let value;
            try {
                if (typeof defaultValue === 'number') {
                    value = defaultValue;
                }
                else if (typeof defaultValue === 'string') {
                    value = parseFloat(defaultValue);
                }
            }
            catch (e) {
                // eat exceptoin
            }
            return { type: ControlTypes.NUMBER, value };
        }
        case 'enum': {
            const value = typeof defaultValue === 'string'
                ? cleanQuotes(defaultValue)
                : undefined;
            const options = Array.isArray(propDef.type)
                ? propDef.type
                : propDef.type.value || splitType;
            if (!Array.isArray(options)) {
                return null;
            }
            return {
                type: ControlTypes.OPTIONS,
                options: options.map((v) => {
                    var _a;
                    const option = cleanQuotes((_a = v.value) !== null && _a !== void 0 ? _a : v);
                    return option === 'undefined' ? undefined : option;
                }),
                value,
            };
        }
        case 'union': {
            const value = typeof defaultValue === 'string'
                ? cleanQuotes(defaultValue)
                : undefined;
            if (typeof propDef.type !== 'object') {
                return null;
            }
            const options = Array.isArray(propDef.type.value)
                ? propDef.type.value.map((v) => v.name)
                : typeof propDef.type.raw === 'string' &&
                    propDef.type.raw.split('|').map(v => v.trim());
            if (!Array.isArray(options)) {
                return null;
            }
            return {
                type: ControlTypes.OPTIONS,
                options: options.map((v) => {
                    var _a;
                    return cleanQuotes((_a = v.value) !== null && _a !== void 0 ? _a : v);
                }),
                value,
            };
        }
        // typescript callback function signature
        case '(() => void)':
        case 'func': {
            return {
                type: ControlTypes.BUTTON,
                label: name,
                onClick,
                value: onClick,
            };
        }
        case 'shape': {
            /* let value;
            try {
              if (propDef.defaultValue && typeof propDef.defaultValue.summary === 'object') {
                value = JSON.parse(propDef.defaultValue.summary);
              }
            } catch (e) {
              // eat exception
            }
            */
            return null;
        }
        default:
            return null;
    }
};
const controlsFromProps = (props) => {
    return props
        ? Object.keys(props)
            .map((key) => {
            const prop = props[key];
            const control = controlFromProps(key, prop);
            if (control) {
                control.defaultValue = control.value;
            }
            return {
                deprecated: prop.deprecated,
                name: key,
                control,
            };
        })
            .filter(p => !p.deprecated && p.control)
            .reduce((acc, prop) => (Object.assign(Object.assign({}, acc), { [prop.name]: prop.control })), {})
        : {};
};

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
const mergeValue = (control, value) => {
    if (typeof control === 'object' && control.type === ControlTypes.OBJECT) {
        const objValue = mergeControlValues(control.value, undefined, value);
        return Object.assign(Object.assign({}, control), { value: objValue });
    }
    return Object.assign(Object.assign({}, control), { value });
};
const mergeControlValues = (controls, controlName, value) => {
    if (controlName) {
        return Object.assign(Object.assign({}, controls), { [controlName]: mergeValue(controls[controlName], value) });
    }
    return Object.keys(controls).reduce((acc, key) => (Object.assign(Object.assign({}, acc), { [key]: mergeValue(controls[key], value[key] === undefined
            ? controls[key].value
            : typeof value[key].value === 'undefined'
                ? value[key]
                : value[key].value) })), {});
};
const resetControlValues = (controls, controlName) => {
    return controlName
        ? controls[controlName].defaultValue
        : Object.keys(controls).reduce((acc, key) => (Object.assign(Object.assign({}, acc), { [key]: controls[key].defaultValue })), {});
};
const canResetControl = (control) => {
    return control.defaultValue !== control.value;
};
const getControlValue = (control) => {
    if (control) {
        const { value } = control;
        switch (control.type) {
            case ControlTypes.TEXT: {
                if (control.escapeValue && typeof value === 'string') {
                    return escape(value);
                }
                break;
            }
            case ControlTypes.OBJECT: {
                if (typeof value === 'object') {
                    return getControlValues(value);
                }
                break;
            }
            case ControlTypes.ARRAY: {
                const arrControl = control;
                if (Array.isArray(arrControl.value) && arrControl.rowType) {
                    const values = arrControl.value.map(row => {
                        const values = Object.keys(arrControl.rowType).reduce((acc, key) => {
                            const valueType = arrControl.rowType[key];
                            if (valueType) {
                                const mockControlType = valueType.type === ControlTypes.OBJECT
                                    ? deepmerge(valueType, {
                                        value: row[key]
                                            ? Object.keys(row[key]).reduce((a, k) => (Object.assign(Object.assign({}, a), { [k]: { value: row[key][k] } })), {})
                                            : undefined,
                                    })
                                    : deepmerge(valueType, {
                                        value: row[key],
                                    });
                                const controlValue = getControlValues({
                                    [key]: mockControlType,
                                });
                                if (controlValue) {
                                    return Object.assign(Object.assign({}, acc), { [key]: controlValue[key] });
                                }
                            }
                            return acc;
                        }, {});
                        return values;
                    });
                    return values;
                }
                break;
            }
            default:
                return value;
        }
        return value;
    }
    return undefined;
};
const getControlValues = (controls) => controls
    ? Object.keys(controls).reduce((acc, key) => {
        const value = getControlValue(controls[key]);
        return Object.assign(Object.assign({}, acc), { [key]: value });
    }, {})
    : {};
const visibleControls = (controls, story) => controls && typeof controls === 'object'
    ? Object.keys(controls)
        .filter(key => {
        const control = controls[key];
        if (control.hidden) {
            return false;
        }
        const args = story === null || story === void 0 ? void 0 : story.arguments;
        //  check if arguments are included in story parameters
        if (args && args.length) {
            const firstArg = args[0];
            return Array.isArray(firstArg.value)
                ? firstArg.value.find(arg => arg.name === key)
                : true;
        }
        return false;
    })
        .map((key, index) => ({
        name: key,
        control: Object.assign(Object.assign({}, controls[key]), { order: controls[key].order === undefined ? index : controls[key].order }),
    }))
        .sort((a, b) => {
        const aOrder = a.control.order || 0;
        const bOrder = b.control.order || 0;
        return aOrder - bOrder;
    })
        .reduce((acc, { name, control }) => (Object.assign(Object.assign({}, acc), { [name]: Object.assign({}, control) })), {})
    : {};
const getControlsCount = (controls) => controls ? Object.keys(controls).length : 0;
const newControlValues = (controls) => {
    return Object.keys(controls)
        .map(name => {
        const control = controls[name];
        const { data } = control;
        if (data === false || data === null) {
            return null;
        }
        const { type } = control;
        switch (type) {
            case ControlTypes.OBJECT: {
                if (typeof control.value === 'object') {
                    return {
                        name,
                        value: Object.assign({}, newControlValues(control.value)),
                    };
                }
                return null;
            }
            case ControlTypes.ARRAY: {
                const arrControl = control;
                if (Array.isArray(arrControl.value) && arrControl.rowType) {
                    const randomValues = {
                        name,
                        value: arrControl.value.map(row => {
                            const values = Object.keys(arrControl.rowType).reduce((acc, key) => {
                                const valueType = arrControl.rowType[key];
                                if (valueType) {
                                    const mockControlType = valueType.type === ControlTypes.OBJECT
                                        ? deepmerge(valueType, {
                                            value: row[key]
                                                ? Object.keys(row[key]).reduce((a, k) => (Object.assign(Object.assign({}, a), { [k]: { value: row[key][k] } })), {})
                                                : undefined,
                                        })
                                        : deepmerge(valueType, {
                                            value: row[key],
                                        });
                                    const newValue = newControlValues({
                                        [key]: mockControlType,
                                    });
                                    if (newValue) {
                                        return Object.assign(Object.assign({}, acc), { [key]: newValue[key] });
                                    }
                                }
                                return acc;
                            }, {});
                            return values;
                        }),
                    };
                    return randomValues;
                }
                break;
            }
            default:
                return {
                    name,
                    value: control.defaultValue,
                };
        }
        return {
            name,
            value: control.defaultValue,
        };
    })
        .reduce((acc, f) => (f ? Object.assign(Object.assign({}, acc), { [f.name]: f.value }) : acc), {});
};
/**
 * transforms the control shortcuts ie controls: { text: 'VALUE'} to full controls
 * @param name the name of the control
 * @param controls all the story assigned controls
 * @param propControls all the smart automatic controls
 * @returns the fully induced ComponentControl
 */
const controlShortcuts = (name, controls, propControls) => {
    const control = controls[name];
    const propControl = (propControls === null || propControls === void 0 ? void 0 : propControls[name]) || {};
    const valueType = typeof control;
    switch (valueType) {
        case 'boolean':
            return Object.assign(Object.assign({ type: ControlTypes.BOOLEAN }, propControl), { value: control });
        case 'string':
            return Object.assign(Object.assign({ type: ControlTypes.TEXT }, propControl), { value: control });
        case 'number':
            return Object.assign(Object.assign({ type: ControlTypes.NUMBER }, propControl), { value: control });
        case 'object': {
            if (control instanceof Date) {
                return Object.assign(Object.assign({ type: ControlTypes.DATE }, propControl), { value: control });
            }
            if (Array.isArray(control)) {
                return Object.assign(Object.assign({ type: ControlTypes.OPTIONS }, propControl), { options: control });
            }
            if ((control === null || control === void 0 ? void 0 : control.type) === ControlTypes.OBJECT &&
                typeof control.value === 'object') {
                return Object.assign(Object.assign(Object.assign({}, control), propControl), { value: Object.keys(control.value).reduce((acc, name) => (Object.assign(Object.assign({}, acc), { [name]: controlShortcuts(name, control.value, propControl) })), {}) });
            }
            return Object.assign(Object.assign({}, propControl), control);
        }
        default:
            return Object.assign(Object.assign({}, propControl), control);
    }
};
/**
 *
 * @param controls all the story controls
 * @param propControls all the automatic prop controls
 * @returns the transformed ComponentControls
 */
const transformControls = (controls, propControls, smart = true) => {
    return controls
        ? Object.keys(smart ? Object.assign(Object.assign({}, propControls), controls) : controls).reduce((acc, key) => {
            const control = controlShortcuts(key, controls, propControls);
            if (control.defaultValue === undefined) {
                const defaultValue = getControlValue(control);
                if (typeof defaultValue !== 'function') {
                    control.defaultValue = defaultValue;
                }
            }
            return Object.assign(Object.assign({}, acc), { [key]: control });
        }, {})
        : undefined;
};
const typeNames = {
    'ControlTypes.TEXT': ControlTypes.TEXT,
    'ControlTypes.NUMBER': ControlTypes.NUMBER,
    'ControlTypes.BOOLEAN': ControlTypes.BOOLEAN,
    'ControlTypes.OPTIONS': ControlTypes.OPTIONS,
    'ControlTypes.DATE': ControlTypes.DATE,
    'ControlTypes.COLOR': ControlTypes.COLOR,
    'ControlTypes.BUTTON': ControlTypes.BUTTON,
    'ControlTypes.OBJECT': ControlTypes.OBJECT,
    'ControlTypes.ARRAY': ControlTypes.ARRAY,
    'ControlTypes.FILES': ControlTypes.FILES,
};
const fixControlTypes = (controls) => {
    return Object.keys(controls).reduce((acc, key) => {
        const control = controls[key];
        if (typeof control === 'object' && control.type) {
            const newType = typeNames[control.type] || control.type;
            return Object.assign(Object.assign({}, acc), { [key]: Object.assign(Object.assign({}, control), { type: newType }) });
        }
        return Object.assign(Object.assign({}, acc), { [key]: control });
    }, {});
};
/**
 * create controls for a story
 * @param story - the story for which to create controls. Will check if the story accepts any arguments
 * @param doc - the document to which the story belongs
 * @param components - the cache of components
 * @returns a collection of controls, or undefined
 */
const getStoryControls = (story, doc, components) => {
    var _a;
    const { controls: storyControls } = story;
    if (!((_a = story.arguments) === null || _a === void 0 ? void 0 : _a.length)) {
        //story has no arguments
        return transformControls(storyControls);
    }
    const smartControls = story.smartControls || {};
    let componentName = getComponentName(story.component);
    if (!componentName ||
        ((!doc.componentsLookup ||
            !components[doc.componentsLookup[componentName]]) &&
            typeof doc.component === 'string')) {
        componentName = doc.component;
    }
    if (componentName) {
        const component = doc.componentsLookup
            ? components[doc.componentsLookup[componentName]]
            : undefined;
        if (component === null || component === void 0 ? void 0 : component.info) {
            const newControls = controlsFromProps(component.info.props);
            const { include, exclude } = smartControls;
            const usedProps = Array.isArray(story.arguments[0].value)
                ? story.arguments[0].value.map(v => v.name)
                : undefined;
            const filteredControls = Object.keys(newControls)
                .filter(key => {
                var _a, _b;
                if (Array.isArray(include) && !include.includes(key)) {
                    return false;
                }
                if (typeof include === 'function') {
                    const prop = (_a = component.info) === null || _a === void 0 ? void 0 : _a.props[key];
                    return include(Object.assign(Object.assign({ name: key }, newControls[key]), { prop }));
                }
                if (Array.isArray(exclude) && exclude.includes(key)) {
                    return false;
                }
                if (typeof exclude === 'function') {
                    const prop = (_b = component.info) === null || _b === void 0 ? void 0 : _b.props[key];
                    return !exclude(Object.assign(Object.assign({ name: key }, newControls[key]), { prop }));
                }
                if (usedProps && !usedProps.includes(key)) {
                    return false;
                }
                return true;
            })
                .reduce((acc, key) => (Object.assign(Object.assign({}, acc), { [key]: newControls[key] })), {});
            const { smart = true } = smartControls;
            const isSmart = story.smartControls !== false && smart;
            return transformControls(storyControls, filteredControls, isSmart);
        }
    }
    return transformControls(storyControls);
};

const defDocType = 'story';
const dateToLocalString = (date) => date
    ? new Date(date).toLocaleDateString('en-US', {
        timeZone: 'UTC',
    })
    : '';
/**
 * Shorthand id to denote the current story
 */
const CURRENT_STORY = '.';
class DefaultStore {
    constructor() {
        this.observers = [];
        this.config = {};
        this.components = {};
        this.docs = {};
        this.packages = {};
        this.stories = {};
        this.addObserver = (observer) => {
            this.observers.push(observer);
        };
        this.removeObserver = (observer) => {
            this.observers = this.observers.filter(o => o !== observer);
        };
        this.updateStory = (story) => {
            if (story) {
                if (story.id) {
                    this.stories = Object.assign(Object.assign({}, this.stories), { [story.id]: story });
                    this.observers.forEach(o => o(story));
                }
            }
        };
    }
}
const getDefaultStore = () => new DefaultStore();
const assignProps = (obj, 
//@ts-expect-error remove story shorthand
_a) => {
    var 
    //@ts-expect-error remove story shorthand
    { storyName, story } = _a, props = __rest(_a, 
    //@ts-expect-error remove story shorthand
    ["storyName", "story"]);
    //preserve component and subcomponents as strings
    const componentName = obj.component;
    const subcomponentsName = obj.subcomponents;
    Object.assign(obj, props);
    if (componentName !== undefined) {
        obj.component = componentName;
    }
    if (subcomponentsName !== undefined) {
        obj.subcomponents = subcomponentsName;
    }
    if (storyName) {
        obj.name = storyName;
    }
    return obj;
};

const storyNameFromExport = storyNameFromExport$1;
const strToId = (str) => str.replace(/\W/g, '-').toLowerCase();
const ensureTrailingSlash = (route) => route.endsWith('/') ? route : route + '/';
const ensureStartingSlash = (route) => route.startsWith('/') ? route : '/' + route;
const removeTrailingSlash = (route, index = 1) => {
    let result = route;
    while (result.length > index && result.endsWith('/')) {
        result = result.slice(0, -1);
    }
    return result;
};
const removeStartingSlash = (route, index = 1) => {
    let result = route;
    while (result.length > index && result.startsWith('/')) {
        result = result.substring(1);
    }
    return result;
};
const getDocPath = (docType, doc, store, name = '', tab) => {
    const pagesConfig = store
        ? store.config.pages
        : undefined;
    const { siteRoot = '/' } = (store === null || store === void 0 ? void 0 : store.config) || {};
    const { basePath = '', sideNav = {} } = (pagesConfig === null || pagesConfig === void 0 ? void 0 : pagesConfig[docType]) || {};
    const { storyPaths } = sideNav;
    const activeTab = (doc === null || doc === void 0 ? void 0 : doc.MDXPage) ? undefined : tab;
    if (storyPaths && doc && doc.stories && doc.stories.length > 0 && store) {
        const { path } = getStoryPath(doc.stories[0], doc, store, activeTab);
        return path;
    }
    const route = doc
        ? doc.route ||
            `${ensureTrailingSlash(basePath)}${strToId(doc.title)}${activeTab ? ensureStartingSlash(activeTab) : ''}`
        : `${ensureTrailingSlash(basePath)}${strToId(name)}${activeTab ? ensureStartingSlash(activeTab) : ''}`;
    return encodeURI(`${siteRoot}${removeStartingSlash(route, 0)}`);
};
const getStoryPath = (storyId, doc, store, tab) => {
    const pagesConfig = store
        ? store.config.pages
        : undefined;
    const { siteRoot = '/' } = (store === null || store === void 0 ? void 0 : store.config) || {};
    const docType = (doc === null || doc === void 0 ? void 0 : doc.type) || defDocType;
    const activeTab = (doc === null || doc === void 0 ? void 0 : doc.MDXPage) ? undefined : tab;
    if (!storyId) {
        return { path: getDocPath(docType, doc, store, undefined, activeTab) };
    }
    const { basePath = '' } = (pagesConfig === null || pagesConfig === void 0 ? void 0 : pagesConfig[docType]) || {};
    const docRoute = removeTrailingSlash((doc === null || doc === void 0 ? void 0 : doc.route) || basePath);
    const story = store === null || store === void 0 ? void 0 : store.stories[storyId];
    const { dynamicId, name } = story || {};
    const id = dynamicId || storyId;
    const route = `${docRoute}${id ? ensureStartingSlash(id) : ''}${activeTab ? ensureStartingSlash(activeTab) : ''}`;
    return {
        path: encodeURI(`${siteRoot}${route}`),
        query: dynamicId ? `story=${name}` : undefined,
    };
};
const formatStoryPath = (storyPath) => `${storyPath.path}${storyPath.query ? `?${storyPath.query}` : ''}`;
const getDocTypePath = (store, basePath) => {
    const { siteRoot = '/' } = (store === null || store === void 0 ? void 0 : store.config) || {};
    return basePath ? `${siteRoot}${removeTrailingSlash(basePath)}` : undefined;
};
const getCategoryPath = (store, category) => {
    const { siteRoot = '/' } = (store === null || store === void 0 ? void 0 : store.config) || {};
    return `${siteRoot}${removeTrailingSlash(category.toLowerCase())}`;
};
const getHomePath = (store) => {
    const { siteRoot = '/' } = (store === null || store === void 0 ? void 0 : store.config) || {};
    return siteRoot.length > 1 ? removeTrailingSlash(siteRoot) : siteRoot;
};
const getRoutePath = (store, route) => {
    const { siteRoot = '/' } = (store === null || store === void 0 ? void 0 : store.config) || {};
    if (route) {
        if (route.startsWith('#')) {
            return route;
        }
        return removeTrailingSlash(`${ensureTrailingSlash(siteRoot)}${removeStartingSlash(route)}`);
    }
    return undefined;
};
const docStoryToId = (docId, storyId) => toId(docId, storyNameFromExport(storyId));
/**
 * maps an exported story to an array of stories. Used for dynamically created stories.
 */
const mapDynamicStories = (story, doc, building) => {
    if (story.dynamic && typeof story.storyFn === 'function') {
        const stories = story.storyFn(doc);
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { id, name } = story, storyProps = __rest(story, ["id", "name"]);
        return Array.isArray(stories)
            ? stories.map(s => (Object.assign(Object.assign(Object.assign({}, storyProps), { dynamicId: building ? undefined : docStoryToId(doc.title, id || name), storyFn: s.renderFn }), s)))
            : [story];
    }
    return [story];
};

/**
 * default export keyword
 */
const defaultExport = 'default';
// source https://usehooks.com/useAsync/
const useAsync = (asyncFunction, immediate = true) => {
    const [status, setStatus] = useState('idle');
    const [value, setValue] = useState(null);
    const [error, setError] = useState(null);
    // The execute function wraps asyncFunction and
    // handles setting state for pending, value, and error.
    // useCallback ensures the below useEffect is not called
    // on every render, but only if asyncFunction changes.
    const execute = useCallback(() => {
        setStatus('pending');
        setValue(null);
        setError(null);
        return asyncFunction()
            .then((response) => {
            setStatus('success');
            setValue(response);
        })
            .catch((error) => {
            setError(error);
            setStatus('error');
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    // Call execute if we want to fire it right away.
    // Otherwise execute can be called later, such as
    // in an onClick handler.
    useEffect(() => {
        if (immediate) {
            execute();
        }
    }, [execute, immediate]);
    return { execute, status, value, error };
};
const isLocalImport = (filePath) => filePath.startsWith('.');

const randomizeSeed = (seed) => faker.seed(seed);
const arrayElements = (arr, c) => {
    const array = arr || ['a', 'b', 'c'];
    let count = 0;
    if (typeof c !== 'number') {
        count = faker.random.number({ min: 1, max: array.length });
    }
    else if (c > array.length) {
        count = array.length;
    }
    else if (c < 0) {
        count = 0;
    }
    const arrayCopy = array.slice();
    const countToRemove = arrayCopy.length - count;
    for (let i = 0; i < countToRemove; i += 1) {
        const indexToRemove = faker.random.number({ max: arrayCopy.length - 1 });
        arrayCopy.splice(indexToRemove, 1);
    }
    return arrayCopy;
};
const canRandomizeControl = (control) => {
    const { data } = control;
    return (data !== false && data !== null && control.type !== ControlTypes.BUTTON);
};
const randomizeData = (controls) => {
    return Object.keys(controls)
        .map(name => {
        var _a;
        const control = controls[name];
        const { data } = control;
        if (!canRandomizeControl(control)) {
            return null;
        }
        // check if control has custom settings for generating data
        if (data && data.name) {
            const fakerType = data.name.split('.');
            const fakerRecurse = (f, sig) => (f ? f[sig] : f);
            const fakrFn = fakerType.reduce((acc, f) => fakerRecurse(acc, f), faker);
            if (typeof fakrFn === 'function') {
                return {
                    name,
                    value: fakrFn(data.options),
                };
            }
        }
        const { type } = control;
        switch (type) {
            case ControlTypes.TEXT: {
                if (name.startsWith('src')) {
                    return {
                        name,
                        value: 'https://picsum.photos/seed/picsum/128/128',
                    };
                }
                return {
                    name,
                    value: faker.name.findName(),
                };
            }
            case ControlTypes.COLOR:
                return {
                    name,
                    value: faker.internet.color(),
                };
            case ControlTypes.BOOLEAN:
                return {
                    name,
                    value: faker.random.boolean(),
                };
            case ControlTypes.NUMBER:
                const step = ((_a = control) === null || _a === void 0 ? void 0 : _a.step) || 1;
                const randomNumber = Math.max(Math.min(faker.random.number({
                    min: control.min || control.value
                        ? control.value / 2
                        : 0,
                    max: control.max || control.value
                        ? control.value * 2
                        : 1,
                }), control.max || Infinity), control.min || -Infinity);
                return {
                    name,
                    value: Math.ceil(randomNumber / step) * step,
                };
            case ControlTypes.OBJECT: {
                if (typeof control.value === 'object') {
                    return {
                        name,
                        value: Object.assign({}, randomizeData(control.value)),
                    };
                }
                return null;
            }
            case ControlTypes.ARRAY: {
                const arrControl = control;
                if (Array.isArray(arrControl.value) && arrControl.rowType) {
                    const randomValues = {
                        name,
                        value: arrControl.value.map(row => {
                            const values = Object.keys(arrControl.rowType).reduce((acc, key) => {
                                const valueType = arrControl.rowType[key];
                                if (valueType) {
                                    const mockControlType = valueType.type === ControlTypes.OBJECT
                                        ? deepmerge(valueType, {
                                            value: row[key]
                                                ? Object.keys(row[key]).reduce((a, k) => (Object.assign(Object.assign({}, a), { [k]: { value: row[key][k] } })), {})
                                                : undefined,
                                        })
                                        : deepmerge(valueType, {
                                            value: row[key],
                                        });
                                    const randValue = randomizeData({
                                        [key]: mockControlType,
                                    });
                                    if (randValue) {
                                        return Object.assign(Object.assign({}, acc), { [key]: randValue[key] });
                                    }
                                }
                                return acc;
                            }, {});
                            return values;
                        }),
                    };
                    return randomValues;
                }
                return null;
            }
            case ControlTypes.OPTIONS: {
                const optionsControl = control;
                let value;
                if (Array.isArray(optionsControl.options)) {
                    if (optionsControl.display === 'multi-select' ||
                        optionsControl.display === 'check' ||
                        optionsControl.display === 'inline-check') {
                        value = arrayElements(optionsControl.options);
                    }
                    else {
                        value = faker.random.arrayElement(optionsControl.options);
                    }
                }
                else if (typeof optionsControl.options === 'object') {
                    if (optionsControl.display === 'multi-select' ||
                        optionsControl.display === 'check' ||
                        optionsControl.display === 'inline-check') {
                        value = arrayElements(Object.values(optionsControl.options));
                    }
                    else {
                        value = faker.random.objectElement(optionsControl.options);
                    }
                }
                else {
                    return null;
                }
                return {
                    name,
                    value,
                };
            }
            default:
                return null;
        }
    })
        .reduce((acc, f) => (f ? Object.assign(Object.assign({}, acc), { [f.name]: f.value }) : acc), {});
};

const DefaultSearchFields = [
    'title',
    'description',
    'stories',
    'components',
];
const docToSearchObject = (doc, fields = DefaultSearchFields) => {
    var _a;
    const value = {
        objectID: doc.title,
    };
    if (fields.includes('title')) {
        value.title = doc.title.replace('/', ' ');
    }
    if (fields.includes('description') && typeof doc.description === 'string') {
        value.description = doc.description;
    }
    if (fields.includes('source')) {
        value.source = doc.source;
    }
    if (fields.includes('author')) {
        value.author = doc.author;
    }
    if (fields.includes('stories')) {
        value.stories = (_a = doc.stories) === null || _a === void 0 ? void 0 : _a.map(story => story.split('-').join(' '));
    }
    if (fields.includes('tags')) {
        value.tags = doc.tags;
    }
    if (fields.includes('components') && doc.componentsLookup) {
        value.components = Object.keys(doc.componentsLookup);
    }
    if (fields.includes('type')) {
        value.type = doc.type;
    }
    return value;
};
const highlightString = (text, search, fieldName, fields = DefaultSearchFields) => text && fields.includes(fieldName)
    ? text.replace(new RegExp(search, 'i'), match => `<em>${match}</em>`)
    : text;
const docToSearchItem = (store, docId, search) => {
    var _a;
    const doc = store.docs[docId];
    const fields = (_a = store.config.search) === null || _a === void 0 ? void 0 : _a.fields;
    return {
        title: highlightString(doc.title, search, 'title', fields),
        author: highlightString(doc.author, search, 'author', fields),
        authorLink: doc.author
            ? getDocPath('author', undefined, store, doc.author)
            : undefined,
        description: typeof doc.description === 'string'
            ? highlightString(doc.description, search, 'description', fields)
            : doc.description,
        type: doc.type,
        tags: doc.tags,
        rawTags: doc.tags
            ? doc.tags.map(tag => highlightString(tag, search, 'tags', fields))
            : undefined,
        date: doc.date,
        link: getDocPath(doc.type || defDocType, doc, store, doc.title),
    };
};
const emptySearchDocuments = (store) => {
    const { emptySearchDocuments = [] } = store.config.search || {};
    return emptySearchDocuments
        .filter(docId => store.docs[docId])
        .map(docId => docToSearchItem(store, docId, ''));
};

const normalizePath = (fillePath) => process.platform === 'win32' ? fillePath.replace(/\\/g, '\\\\') : fillePath;

export { CURRENT_STORY, ControlTypes, DefaultSearchFields, assignProps, canRandomizeControl, canResetControl, cleanQuotes, controlFromProps, controlsFromProps, convertConfig, dateToLocalString, deepMerge, deepMergeReplaceArrays, deepmerge, defDocType, defaultBuildConfig, defaultExport, defaultRunConfig, docStoryToId, docToSearchItem, docToSearchObject, emptySearchDocuments, ensureStartingSlash, ensureTrailingSlash, extractStoryProps, fixControlTypes, formatStoryPath, getCategoryPath, getComponentName, getControlValue, getControlValues, getControlsCount, getDefaultStore, getDocPath, getDocTypePath, getHomePath, getRoutePath, getStoryControls, getStoryPath, highlightString, isLocalImport, loadDefaultExport, loadPageTab, mapDynamicStories, deepMergeReplaceArrays as merge, mergeConfig, mergeControlValues, mergeStoryProps, newControlValues, normalizePath, randomizeData, randomizeSeed, removeStartingSlash, removeTrailingSlash, resetControlValues, storyNameFromExport, strToId, transformControls, useAsync, visibleControls };
