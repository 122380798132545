const customLoaderOptions = (config, loader, defaultOptions) => {
    var _a;
    const customOptions = (_a = config.loaders) === null || _a === void 0 ? void 0 : _a[loader];
    return typeof defaultOptions !== 'string' && typeof customOptions === 'object'
        ? Object.assign(Object.assign({}, defaultOptions), customOptions) : customOptions === null || customOptions === false
        ? false
        : defaultOptions || {};
};
const defaultPresets = ['react', 'react-docgen-typescript'];
const defaultCompileProps = {
    presets: defaultPresets,
};
const defBundleName = 'component-controls.js';
const defCssFileName = 'component-controls.css';

export { defBundleName as a, defCssFileName as b, customLoaderOptions as c, defaultCompileProps as d };
