import { getDefaultStore, mergeConfig, defaultRunConfig, convertConfig, defDocType, deepMerge, mergeStoryProps, mapDynamicStories, docStoryToId, storyNameFromExport, getStoryControls } from '@component-controls/core';
import { render } from '@component-controls/render/react';

const loadStore = (store, building) => {
    const globalStore = getDefaultStore();
    try {
        const { error, stores, packages: loadedPackages, components: loadedComponents, config = {}, buildConfig = {}, } = store;
        if (stores) {
            globalStore.config = mergeConfig(defaultRunConfig, convertConfig(mergeConfig(buildConfig, config)));
            if (!globalStore.config.renderFn) {
                globalStore.config.renderFn = render;
            }
            globalStore.search = store.search;
            stores.forEach(s => {
                var _a, _b;
                const storeDoc = s.doc;
                const storeStories = s.stories;
                if (storeDoc && storeStories && s.stories) {
                    const page = ((_a = globalStore.config.pages) === null || _a === void 0 ? void 0 : _a[storeDoc.type || defDocType]) || ((_b = defaultRunConfig.pages) === null || _b === void 0 ? void 0 : _b[defDocType]);
                    const pageLayout = {
                        contextSidebar: page.contextSidebar,
                        fullPage: page.fullPage,
                        navSidebar: page.navSidebar,
                    };
                    storeDoc.renderFn = storeDoc.renderFn || globalStore.config.renderFn;
                    //storybook compat
                    storeDoc.controls = storeDoc.controls || storeDoc.args;
                    const doc = deepMerge(pageLayout, mergeStoryProps(store.config, storeDoc));
                    globalStore.docs[doc.title] = doc;
                    Object.keys(storeStories).forEach((storyName) => {
                        const exportedStory = storeStories[storyName];
                        const stories = mapDynamicStories(exportedStory, doc, building);
                        stories.forEach(docStory => {
                            const id = docStory.id || docStory.name;
                            if (doc.title && id) {
                                const story = Object.assign(Object.assign({}, docStory), { id: docStoryToId(doc.title, id), rawId: id, name: storyNameFromExport(docStory.name), doc: doc.title });
                                //storybook compat
                                story.controls = story.controls || story.args;
                                Object.assign(story, mergeStoryProps(doc, story));
                                story.controls = getStoryControls(story, doc, loadedComponents);
                                if (!doc.stories) {
                                    doc.stories = [];
                                }
                                doc.stories.push(story.id);
                                globalStore.stories[story.id] = story;
                            }
                        });
                    });
                }
            });
            globalStore.error = error;
            globalStore.packages = loadedPackages;
            globalStore.components = loadedComponents;
            const { storySort } = globalStore.config || {};
            let pages = Object.keys(globalStore.docs).map(key => globalStore.docs[key]);
            if (storySort) {
                pages = pages.sort((a, b) => {
                    const sort = storySort(a.title, b.title);
                    if (sort !== 0) {
                        return sort;
                    }
                    return pages.indexOf(a) - pages.indexOf(b);
                });
            }
            //split documents by their common 'parent'
            const sortedDocs = pages
                .map(doc => {
                const levels = doc.title.split('/');
                const parent = levels.slice(0, -1).join('/');
                return { id: doc, parent };
            })
                .sort((a, b) => {
                if (a.parent === b.parent) {
                    return ((globalStore.docs[a.id.title].order || 0) -
                        (globalStore.docs[b.id.title].order || 0));
                }
                return 0;
            });
            globalStore.docs = sortedDocs.reduce((acc, d) => {
                const doc = d.id;
                return Object.assign(Object.assign({}, acc), { [doc.title]: doc });
            }, {});
        }
    }
    catch (e) {
        console.error(e);
    }
    return globalStore;
};

export { loadStore as l };
