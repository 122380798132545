'use strict';

var core = require('@component-controls/core');

const composeFn = ({ story, doc, options }) => {
    if (!story) {
        throw new Error(`Invalid story`);
    }
    const controls = story.controls;
    const values = core.getControlValues(controls);
    //parameters added to avoid bug in SB6 rc that assumes parameters exist
    const context = Object.assign({ story,
        doc,
        controls }, options);
    const { decorators: globalDecorators = [] } = options || {};
    const { decorators: storyDecorators = [] } = story;
    const decorators = core.deepMerge(globalDecorators, storyDecorators);
    const sortedDecorators = decorators.reverse();
    let renderFn = story.storyFn;
    for (let i = 0; i < sortedDecorators.length; i += 1) {
        const decorator = sortedDecorators[i];
        const childFn = renderFn;
        const nextRenderFn = (_, nextContext) => childFn(values, Object.assign(Object.assign({}, context), nextContext));
        renderFn = () => decorator(nextRenderFn, Object.assign(Object.assign({}, context), { renderFn: nextRenderFn }));
    }
    return { renderFn, values, context };
};

exports.composeFn = composeFn;
